
import '../Dashboard/userprofile.css';
import Gap from '../../component/Gap/Gap';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import pc from '../../assets/pc.png'
import Cards2 from '../../component/card/cardStrategy';
import DatePicker from "react-datepicker";
import Modal from '../../component/modal/modal';
import '../AdminWithdraw/adminWithdraw.css'
import Empty from '../../component/empty/empty';
import logoCalendar from "../../assets/CalendarBlues.png"
import Refresh from "../../assets/RefreshWhite.png"
import Navbar from '../../component/navbar/navbar';
import { useNavigate } from "react-router-dom";
import trade1 from '../../assets/screen.png'
import trade2 from '../../assets/trade2.png'
import trade3 from '../../assets/trade3.png'
import profilewhite from '../../assets/userGray.png'
import FETCH_API from '../../config';
import { Card, Form, FloatingLabel, Col } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import formatterMoney from '../../component/formatMoney';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { CalendarIcon} from '@heroicons/react/24/outline';
import * as XLSX from "xlsx";
import { prepareImport } from '../../component/excel/prepareImport';
import SideBar from '../../component/sideBar/sideBar';


export default function AdminSetting({
}){
    const fileInputRef = React.createRef();
    const navigate = useNavigate();
    const buttonRefReport = React.useRef(null);
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 768 && window.innerWidth <= 1024);
    const [dateWallet,setDateWallet]=React.useState(formatDateFirst(new Date()))
    const [date2Wallet,setDate2Wallet]=React.useState(formatDatefix(new Date()))
    const Id = localStorage.getItem('ID')
    const [user,setUser]=React.useState([])
            function formatDateFirst(params) {
        return moment(params).format('YYYY-MM-01')
    }
                function formatDateNew(params) {
        return moment(params).format('YYYY-MM-DD')
    }
                    function formatDatefix(params) {
        return moment(params).format('2025-MM-DD')
    }
    const handleClick = () => {
		if (buttonRefReport.current) {
            // buttonRef.current.classList.remove('animate-click');
            void buttonRefReport.current.offsetWidth; // Trigger reflow to reset animation
            // buttonRef.current.classList.add('animate-click');
            buttonRefReport.current.classList.add('color-change-report');
            setTimeout(() => {
                buttonRefReport.current.classList.remove('color-change-report');
            }, 300); // Adjust this timeout to match your animation duration
        }
    };

        function formatDate(params) {
        return moment(params).format('MMMM DD, YYYY')
    }


    async function getWithdraw(params) {
        try {
            let body={
                "id": Id,
                "page": 1,
                "pageSize": 9999,
                "startDate": formatDateNew(dateWallet),
                "endDate": formatDateNew(date2Wallet),
                "type": "withdraw" // "deposit", "withdraw", "register", atau "" untuk get semua type
            }
            const laporan = await FETCH_API("wallet/get-all-transactions", body,navigate)
            console.log(laporan)
            if(laporan.status === 'OK'){
                // toast.success(laporan.message)
                setUser(laporan.transactions)
                // navigate("/")
            }
            else{
                if(laporan.message!==undefined){
                    toast.error(laporan.message)
                }
                else{
                    toast.error(laporan.error.message)
                }
            }
        } catch (error) {
            toast.error(error)
        }
        
    }

    React.useEffect(()=>{
        getWithdraw()
    },[])

        async function SaveWithdraw(params) {
          try {
            const laporan = await FETCH_API("wallet/approve-withdraw", {
                "userId": Id,
                "transaction_id": params
            },navigate)
            console.log(laporan.users)
            if(laporan.status === 'OK'){
                toast.success(laporan.message)
                getWithdraw()
            }
            else{
                if(laporan.message!==undefined){
                    toast.error(laporan.message)
                }
                else{
                    toast.error(laporan.error.message)
                }
            }
        } catch (error) {
            toast.error(error)
        }
    }


    return(
        <div style={{ width:"100vw" }}>
                    <SideBar isMobile={isMobile} isTablet={isTablet}/>
            <Navbar/>
            <Gap height={100}></Gap>
            <div className='main-profile' style={{ marginLeft:"5%",width:"90vw",padding:"3%",backgroundColor:"white",borderRadius:5,marginBottom:"10%"}}>
                <div style={{ marginBottom:"2%",display:"flex",justifyContent:"center",alignItems:"center",width:"100%" }}>
                    <text className="text-judul" style={{color:'#2A2626',fontSize:'2.5vh'}}>User Withdraw</text>
                </div>
                <div style={{width:'100%',height:'8vh',display:'flex',justifyContent:'flex-start',alignItems:'center',paddingLeft:isMobile||isTablet?0:'5%'}}>
                        <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
                            <DatePicker 
                                onChange={(v)=>{
                                    setDateWallet(formatDate(v))
                                    }}
                                customInput={
                                <div
                                style={{backgroundColor:'rgb(238, 238, 238)',border:'1px solid rgb(48, 48, 48)',borderRadius:5,width:isMobile||isTablet?'180px':'180px',height:'48px',justifyContent:'center',alignItems:'center',display:'flex',cursor:'pointer'}}
                                >
                                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <CalendarIcon  style={{width:'2vh',height:'2vh'}}></CalendarIcon>
                                    <Gap width={'1vh'}></Gap>
                                    <text className='text-des' style={{color:'rgb(48, 48, 48)',fontSize:'15px'}}>{formatDate(dateWallet)}</text>
                                    </div>
                                </div>}
                            />
                        </div>
                        <Gap width={isMobile||isTablet?'10px':'2px'}></Gap>
                        <div style={{width:'5vh',height:'5vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <text className='text-des' style={{color:'rgb(48, 48, 48)',paddingRight:'5%'}}>To</text>
                        </div>
                        <Gap width={isMobile||isTablet?'10px':'2px'}></Gap>
                        <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
                            <DatePicker 
                                onChange={(v)=>{
                                    setDate2Wallet(formatDate(v))
                                    }}
                            customInput={
                                <div
                                style={{backgroundColor:'rgb(238, 238, 238)',border:'1px solid rgb(48, 48, 48)',borderRadius:5,width:isMobile||isTablet?'180px':'180px',height:'48px',justifyContent:'center',alignItems:'center',display:'flex',cursor:'pointer'}}
                                >
                                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <CalendarIcon  style={{width:'2vh',height:'2vh'}}></CalendarIcon>
                                    <Gap width={'1vh'}></Gap>
                                    <text className='text-des' style={{color:'rgb(48, 48, 48)',fontSize:'15px'}}>{formatDate(date2Wallet)}</text>
                                    </div>
                                </div>}
                            />
                        </div>
                        <Gap width={isMobile||isTablet?'10px':'10px'}></Gap>
                        {
                            (!isMobile&&!isTablet)&&
                            <div onClick={()=>{
                                // handleClick()
                                // reportWallet('ref')
                                getWithdraw()
                                        }} style={{width:isMobile||isTablet?'30%':'5%',marginTop:isMobile||isTablet?'5%':0,marginBottom:isMobile||isTablet?'5%':0}}  className='robot-center'>
                                            <div ref={buttonRefReport}
                                            style={{ 
                                                backgroundColor: 'rgb(20, 42, 83)',
                                                borderRadius: '10px',
                                                width: '45px',
                                                height: '46px',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                                            }}
                                            >
                                                <div style={{display:'flex'}}>
                                                    <img src={Refresh} style={{ width:'20px',height:'20px' }}></img>
                                                    {/* <Gap width={"10px"}/>
                                                <text className='text-judul' style={{color:'white'}}>Refresh</text> */}
                                                </div>
                                            </div>
                            </div>
                        }
                </div>
                                    {
                    user?.length!==0&&
                    <div style={{width:isMobile||isTablet?'100%':'90%',margin:isMobile||isTablet?0:'5%',boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',borderTopLeftRadius:10,borderTopRightRadius:10 }}>
                            <div style={{ width:'100%',height:'48px',backgroundColor:'rgb(48, 48, 48)',borderTopLeftRadius:10,borderTopRightRadius:10,display:'flex'}}>
                                <div style={{ width:'20%',height:'48px',display:'flex',justifyContent:'center',alignItems:'center'  }}>
                                    <text className='text-judul' style={{ fontSize:'14px' }}>id</text>
                                </div>
                                <div style={{ width:'20%',height:'48px',display:'flex',justifyContent:'center',alignItems:'center'  }}>
                                    <text className='text-judul' style={{ fontSize:'14px' }}>Username</text>
                                </div>
                                <div style={{ width:'20%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                    <text className='text-judul' style={{ fontSize:'14px' }}>tanggal</text>
                                </div>
                                <div style={{ width:'20%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                    <text className='text-judul' style={{ fontSize:'14px' }}>Status</text>
                                </div>
                                <div style={{ width:'20%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                    <text className='text-judul' style={{ fontSize:'14px' }}>Nominal</text>
                                </div>
                                <div style={{ width:'20%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                    <text className='text-judul' style={{ fontSize:'14px' }}>Action</text>
                                </div>
                            </div>
                            {
                                    user?.map((items,index)=>{
                                        let backgroundColor = index % 2 === 0 ?  'rgb(248, 248, 248)':'rgb(228, 228, 228)';
                                         
                                        return(
                                           
                                            <div style={{ width:'100%',height:'48px',backgroundColor,display:'flex'}}>
                                                <div style={{ width:'20%',height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                                    <text className='text-des' style={{ fontSize:isTablet||isMobile?'11px':'14px',color:'rgb(48, 48, 48)' }}>{items?.id?.substring(0,6)}</text>
                                                </div>
                                                <div style={{ width:'20%',height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                                    <text className='text-des' style={{ fontSize:isTablet||isMobile?'11px':'14px',color:'rgb(48, 48, 48)' }}>{items?.username}</text>
                                                </div>
                                                <div style={{ width:'20%',height:'48px',display:'flex',justifyContent:'center',alignItems:'center'  }}>
                                                    <text className='text-des' style={{ fontSize:isTablet||isMobile?'11px':'14px',color:'rgb(48, 48, 48)' }}>{formatDate(items?.created_date)}</text>
                                                </div>
                                                <div style={{ width:'20%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                                    <text className='text-des' style={{color:items.status>0?'#6AC673':items.status==0?"#FFC555":"#F74949",fontSize:(isTablet||isMobile)?'11px':'14px'}}>{items.status==-1?"Canceled":items.status==0?"Pending":"Paid"}</text>
                                                </div>
                                                <div style={{ width:'20%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                                    <text className='text-des' style={{color:'rgb(48, 48, 48)',fontSize:(isTablet||isMobile)?'11px':'14px'}} >Rp. {formatterMoney(items.amount)}</text>
                                                </div>
                                                <div style={{ width:'20%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                                {   items.status==0&&
                                                    <div onClick={()=>{SaveWithdraw(items.id)}} style={{cursor:"pointer",display:'flex',justifyContent:'center',alignItems:'center',height:'auto',width:isTablet||isMobile?'80%':'50%'}}>
                                                        <div style={{padding:(isTablet||isMobile)?'5%':'5%',backgroundColor:'#6AC673',width:(isTablet||isMobile)?'100%':'85%',borderRadius:10,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                            <text className='text-des' style={{color:'white',fontSize:(isTablet||isMobile)?'11px':'14px'}}>Approve</text>
                                                        </div>
                                                    </div>
                                                }
                                                </div>

                                            </div>
                                        )
                                         
                                    })
                                }
                        </div>
                    }
                    {
                        user?.length===0&&
                        <Empty data={"User"}></Empty>
                    }
            </div>
            <ToastContainer />
        </div>
    )
}

const styles = {
  container: {
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    marginTop:'5%'
  },
  header: {
    // fontSize: '6vw',
    // marginBottom: '20px',
    // color: '#3498db',
  },
  totalsContainer: {
    backgroundColor: '#F5F9FD',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent:"space-between",
    alignItems: 'center',
  },
  totalItem: {
    flex: 1,
    padding: '10px',
  },
  label: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#333',
  },
  value: {
    fontSize: '1.5rem',
    color: '#2ecc71',
    marginBottom: '20px',
  },
    value2: {
    fontSize: '1.5rem',
    color: '#e74c3c',
    marginBottom: '20px',
  },
  percentageValue: {
    fontSize: '1.5rem',
    color: 'gray',
  },
};