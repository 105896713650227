import React from 'react'
import { useRef } from 'react';
import UruzB from '../../assets/UruzBlue.png'
import UruzA from '../../assets/UruzWhite.png'
import Gap from '../../component/Gap/Gap';
import Button from '../../component/button/button';
import Input from '../../component/Input/input';
import FETCH_API from '../../config';
import {useLocation,useNavigate} from 'react-router-dom';
import Button2 from '../../component/button/button2';
import image from '../../assets/image6.png'
import "./login.css"
import { ToastContainer, toast } from 'react-toastify';
import { baseURL } from '../../Constant';
import bai from "../../assets/bai-logo.png"
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from '../../component/loading/loading';

function Home() {
	const navigate=useNavigate()
	const [loading,setLoading]=React.useState(false)
	const windowSize = useRef([window.innerWidth, window.innerHeight]);
	const [data,setData]=React.useState({name:'',pass:'',ref:'',email:""})
	const location = useLocation();
	const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 768 && window.innerWidth <= 1024);
	const [forget,setForget]=React.useState(false)
	const [isClicked, setIsClicked] = React.useState(false);
	const buttonRefs = React.useRef(null);

    const handleClick = () => {
		if (buttonRefs.current) {
            void buttonRefs.current.offsetWidth;
            buttonRefs.current.classList.add('colors-changes-login');
            setTimeout(() => {
                buttonRefs.current.classList.remove('colors-changes-login');
            }, 300);
        }
    };

	React.useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width <= 768);
            setIsTablet(width > 768 && width <= 1024);
        };

		const getParameterByName = (name, url = window.location.href) => {
			name = name.replace(/[\[\]]/g, '\\$&');
			var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
				results = regex.exec(url);
			if (!results) return null;
			if (!results[2]) return '';
			return decodeURIComponent(results[2].replace(/\+/g, ' '));
		 }

		console.log(getParameterByName("param"))
		let params=getParameterByName("param")
		if(params=="update"){
			toast.info("Data berhasil diubah, Silahkan login kembali dengan Email yg sudah anda daftarkan")
		}
		else if(params=="reset"){
			toast.info("Password Berhasil Diubah Silahkan login kembali")
		}

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowSize]);


	

	async function loginAkun(){
		try {
			setLoading(true)
			if(data.email==""&&data.pass==""){
				return
			}
			const login = await FETCH_API("users/login", {
				"username": data.email,
				"password": data.pass,
				"successRedirectUrl": baseURL+"#/login"
			})
			if(login.status=="OK"){
				if(login.data!==null&&login.data!==undefined){
					window.location.href = login.data.invoice_url
				}
				else{
					toast.success(login.message)
					localStorage.setItem("Token",login.token)
					localStorage.setItem("ID",login.id)
					localStorage.setItem("User",data.email)
					if(login.role=="admin"){
						localStorage.setItem("UserType","admin")
						navigate("/usersetting");
					}
					else{
						localStorage.setItem("UserType","user")
						navigate("/copytrading");
					}
					localStorage.setItem("dashboard","login")
					setLoading(false)
				}
			}
			else{
				console.log("login",login)
				if(login.data!==null&&login.data!==undefined){
					window.location.href = login.data.invoice_url
				}
				else{
					if(login.message!==undefined){
						console.log("masuk sini")
						toast.error(login.message)
						
					}
					else{
											console.log("masuk sini 2")
						toast.error("Gagal Login, Email atau Password Salah")
					}
				}
				setLoading(false)
			}	
		} catch (error) {
			toast.error(error)
			setLoading(false)
		}
	}

	async function onForget(){
		try {
			setLoading(true);
			if(data.email.length>0){
			const forget = await FETCH_API("users/forget/password", {
				"baseUrl": baseURL+"#",
				"id": data.email,
				"subject": "Change Password"
			})
			console.log(forget)
			if(forget.status=="OK"){
				toast.success(forget.message)
			}
			else{
				if(forget.message!==undefined){
					toast.error(forget.message)
				}
				else{
					toast.error(forget.error.message)
				}
			}
			setLoading(false)
		}	
		} catch (error) {
			setLoading(false);
			toast.error(error);
		}
	}

    return(

        <div className='main-css' style={{ backgroundColor:isMobile||isTablet?"#ebebeb":"" }}>
			<div style={{ backgroundColor:isMobile||isTablet?"":"#ebebeb" ,boxShadow:isMobile||isTablet?"":"1px 1px 10px 0px black",width:isTablet||isMobile?"90%":"30%",height:"65vh",borderRadius:10,display:"flex",flexDirection:"column",justifyContent:"center"}}>
			<div className='login-padding-content'>
				<div style={{ display: 'grid', alignItems: 'center',marginBottom:'3%' }}>
					{
						location.state === 'regis'?
						<div  style={{ fontSize:isMobile || isTablet ?28 : '2.2vw',color:'#344054',textAlign:"center"}} className="text-judul">Buat Akun Baru</div>
						:
						<div  style={{ fontSize:isMobile || isTablet ?28 : '2.2vw',color:'#344054',textAlign:"center"}} className="text-judul">Login</div>

					}
					
					{/* <div style={{ fontSize: isMobile || isTablet ?18:'1.1vw',color:'#344054',opacity:0.6,fontWeight:'lighter'}} className="text-des">Please fill your detail to access your account</div> */}
				</div>
				{
					forget==false?
					<div
					style={{

					}}
					>

						<Gap height={15} />
						<Input onChange={(val)=>{
							console.log(val)
							setData((prev) => ({
								...prev,
								email:val.target.value
							}))
						}} type="input" id="input1" onKeyDown={(event)=>{
							console.log("isinya apa",event.key)
							if (event.key === "Enter") {
								event.preventDefault();
								document.getElementById("input2").focus();
							}
							}} placeholder="E-mail / Phone" label="E-mail / Phone"/>

						<Gap height={15} />
						<Input id="input2" type="password" onChange={(val)=>{
							setData((prev) => ({
								...prev,
								pass:val.target.value
							}))
						}} placeholder="*******" label="Password" onKeyDown={(event)=>{
							console.log("isinya apa",event.key)
							if (event.key === "Enter") {
								loginAkun()
							}
							}} />
					
					</div>
					:
					<div
					style={{

					}}
					>

						<Gap height={15} />
						<Input onChange={(val)=>{
							console.log(val)
							setData((prev) => ({
								...prev,
								email:val.target.value
							}))
						}} type="email" placeholder="sample@gmail.com" label="E-mail" onKeyDown={(event)=>{
							console.log("isinya apa",event.key)
							if (event.key === "Enter") {
								onForget()
							}
							}}/>
					
					</div>
				}
			</div>
			<Gap height={50} />
			{
				forget==false?
				<div className='padding-content'>
						<button onClick={()=>{loginAkun()}}
						className={'background-login'}
						ref={buttonRefs}
						>
							<div style={{display:'flex'}}>
							{/* <img src={Save} style={{width:'2.5vh',height:'2.5vh'}}></img> */}
							{/* <Gap width={'2vh'}></Gap> */}
							<text className='text-judul' style={{color:'white'}}>LOGIN</text>
							</div>
						</button>
				</div>
				:
				<div className='padding-content'>
					<button onClick={()=>{onForget()}}
						className={'background-login'}
						ref={buttonRefs}
						>
							<div style={{display:'flex'}}>
							{/* <img src={Save} style={{width:'2.5vh',height:'2.5vh'}}></img> */}
							{/* <Gap width={'2vh'}></Gap> */}
							<text className='text-judul' style={{color:'white'}}>SUBMIT</text>
							</div>
						</button>
				</div>
			}
			

				<div className="text-des box-button-text">
					<text className='text-button-before'>Don't have an account? </text>
					<text onClick={()=>{
						// onForget()
						navigate("/Regis");
					}} className='text-button-click' style={{marginLeft:'0.5%'}}>Register Here </text>
				</div>
				<div className="text-des text-button" onClick={()=>{
					// onForget()
					setForget(!forget)
				}}  >{forget==false?"Forget Password?":"Login to your account"}</div>
			</div>	
			<ToastContainer />
      </div>
    )
}

export default Home