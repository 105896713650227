import React from 'react'
import empty from "../../assets/pana.png"
import Gap from '../Gap/Gap'


export default function Empty({ data}) {
    return (
      <div style={{height:'30vh' ,display:'flex',justifyContent:'center',alignItems:'center'}}>
          <div style={{ display:'flex',flexDirection:'column' ,justifyContent:'center',alignItems:'center'}}>
              <img src={empty} style={{ width:'15vh',height:'15vh' }}></img>
              <Gap height={'2vh'} />
              <text className='text-judul' style={{color:'#2A2626',fontSize:'2vh' }}>Data {data} Kosong</text>
          </div>
      </div>
    )
}