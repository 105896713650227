import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import "./card.css"
import React from 'react';
import robotTrading from '../../assets/robotTrading.png'
import ceklis from '../../assets/ceklis.png'
import { CheckIcon,ChartBarSquareIcon} from '@heroicons/react/24/outline';

function Cards({ title, deskripsi,isMobile = false, isTablet = false, duration,items, index, tampungindex, handleCardClick}) {    
    const [isClicked, setIsClicked] = React.useState(false);
    const handleClick = () => {
        handleCardClick(items,index); // Notify the parent component about the click event
    };

  return (
      <div className='card-main-dev2' style={{height:'auto',width:isMobile||isTablet?'95%':'28%',cursor:'pointer',padding:isMobile||isTablet?'5%':'2%',backgroundImage:index==tampungindex?'linear-gradient(180deg, rgb(20, 42, 83), rgb(25, 60, 95))':'linear-gradient(180deg, rgba(245, 249, 253, 1), rgba(245, 249, 253, 1)'}} onClick={handleClick}>
          {/* <div style={{ width: '20%', height: isMobile||isTablet?'auto':'50%' ,display:'flex',justifyContent:'flex-start',alignItems:'center',}}>
              <ChartBarSquareIcon style={{width:'30px',height:'30px',color:index==tampungindex?"white":'#344054'}}/>
          </div> */}
          <div style={{ width: '100%', height: isMobile||isTablet?'auto':'50%' ,display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'flex-start'}}>
          <div style={{height:'3vh'}}></div>
              <text className='text-judul' style={{ fontSize: isMobile || isTablet ? '20px' : '2.2vh',  color: index==tampungindex?"white":'#344054' }}>{title}</text>
            <div style={{height:'3vh'}}></div>
            <div style={{ display:'flex',alignItems:'center'}}>
              <CheckIcon style={{width:'17px',height:'17px',color:index==tampungindex?"white":'#344054'}}/>
              <div style={{width:'1vh'}}></div>
              <text className='text-judul' style={{ fontSize: isMobile || isTablet ? '15px' : '1.5vh', color: index==tampungindex?"white":'#344054' }}>Management Fee {items.commission}% Dari Keuntungan</text>
            </div>
            
              <div style={{height:'3vh'}}></div>
              <text className='text-des' style={{ fontSize: isMobile || isTablet ? '15px' : '1.5vh', color: index==tampungindex?"white":'#344054',textAlign:'start' }}>{items.description}</text>
          </div>
          {/* <div style={{ width:'100%',display:'flex',justifyContent:'center',alignItems:'center', marginTop:'10%' }}>
                <div onClick={()=>{
                  // onChoose(items.id)
                  // console.log(items)
                  }} style={{width:isMobile||isTablet?'50%':'50%',marginTop:isMobile||isTablet?'5%':0,marginBottom:isMobile||isTablet?'5%':0}}  className='robot-center'>
                    <div 
                      style={{ 
                        backgroundColor: 'rgba(39, 94, 148, 1)',
                        borderRadius: '10px',
                        width: '160px',
                        height: '28px',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                       }}
                    >
                        <div style={{display:'flex'}}>
                        <text className='text-judul' style={{color:'white'}}>Subscription</text>
                        </div>
                    </div>
                </div>
          </div> */}
          {/* <div style={{ height:'auto' }}>
            <text onClick={()=>{setDataModal(items);setOpenModal(!openModal)}} classname="text-judul" style={{color:'#3582B8',fontSize:isMobile||isTablet?'18px':'2vh',cursor:'pointer'}}>See More</text>
          </div> */}
      </div>
  );
}

export default Cards;