import './about.css';
import Navbar from '../../component/navbar/navbar';
import Button from '../../component/button/button';
import Gap from '../../component/Gap/Gap';
import trade from '../../assets/animasihome.gif'
import meet from '../../assets/meet.jpg'
import { useNavigate } from "react-router-dom";
import Cards from '../../component/card/card';
import React from 'react';
import Footer from '../../component/footer/footer';
import trade1 from '../../assets/trade.png'
import trade2 from '../../assets/trade2.png'
import trade3 from '../../assets/trade3.png'
import pc from '../../assets/pc.png'
import book from '../../assets/book.png'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';


function Home() {
    const navigate = useNavigate();
    const [token,setToken]=React.useState("")
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 768 && window.innerWidth <= 1024);
    const [user,setUser]=React.useState('')

    const handleDragStart = (e) => e.preventDefault();


    React.useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width <= 768);
            setIsTablet(width > 768 && width <= 1024);
            console.log(isMobile,isTablet)
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    return (
        <div className="App">
            <Navbar/>
            <Gap height={'12vh'}></Gap>
            <div style={{width:'100%',height:'150vh',display:'flex',justifyContent:'flex-start',alignItems:'center',flexDirection:'column'}}>
                <div style={{width:'100%',marginBottom:'5%'}}>
                <text className='text-judul' style={{color:'black',fontSize:'5vh'}}>About</text>
                </div>
                <div className="app-container">
                <header>
                    <h1>Uruz Solusi Teknologi</h1>
                    <p>Biar kami yang Uruz</p>
                </header>
                
                <section className="product-section">
                    <h2>Produk Kami</h2>

                    <div className="product">
                    <h3>Solusi Point of Sale (POS)</h3>
                    <p>Transformasikan pengalaman ritel Anda dengan sistem POS canggih kami.</p>
                    </div>

                    <div className="product">
                    <h3>Enterprise Resource Planning (ERP)</h3>
                    <p>Satukan dan optimalkan proses bisnis Anda dengan paket ERP kami.</p>
                    </div>

                    <div className="product">
                    <h3>Sistem Manajemen</h3>
                    <p>Sistem Manajemen kami yang dapat disesuaikan dirancang untuk memenuhi kebutuhan unik industri Anda.</p>
                    </div>

                </section>

                <section className="why-choose-section">
                    <h2>Mengapa Memilih Uruz Solusi Teknologi</h2>

                    <ul>
                    <li><strong>Inovasi dan Adaptabilitas:</strong> Kami selalu berada di depan kurva teknologi.</li>
                    <li><strong>Solusi yang Dapat Disesuaikan:</strong> Produk kami sangat dapat disesuaikan untuk memenuhi kebutuhan unik Anda.</li>
                    <li><strong>Pendekatan Berorientasi Pelanggan:</strong> Kami menyediakan dukungan khusus, pelatihan, dan bantuan berkelanjutan.</li>
                    <li><strong>Keamanan dan Kepatuhan:</strong> Keamanan data Anda adalah prioritas kami.</li>
                    </ul>
                </section>

                <section className="contact-section">
                    <h2>Hubungi Kami</h2>

                    <p>Transformasikan bisnis Anda dengan Uruz Solusi Teknologi. Mari memulai perjalanan keunggulan teknologi bersama-sama.</p>

                    <p>Website: <a href="https://www.uruz.id" target="_blank" rel="noopener noreferrer">https://www.uruz.id</a></p>
                    <p>Email: <a href="mailto:Sales@uruz.id">Sales@uruz.id</a></p>
                    <p>Telepon: +62 817392286</p>
                </section>
                </div>
            </div>


            <Footer                 
                isMobile={isMobile}
                isTablet={isTablet}/>

        </div>
    );
}

export default Home;
