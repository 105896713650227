import './home.css';
import Navbar from '../../component/navbar/navbar';
import Carousel from "better-react-carousel";
import Footer from '../../component/footer/footer';
import Button from '../../component/button/button';
import Gap from '../../component/Gap/Gap';
import trade from '../../assets/animasihome.gif'
import meet from '../../assets/meet.jpg'
import { useNavigate,useLocation} from "react-router-dom";
import Cards from '../../component/card/card';
import React from 'react';
import trade1 from '../../assets/trade.png'
import trade2 from '../../assets/trade2.png'
import trade3 from '../../assets/trade3.png'
import pc from '../../assets/pc.png'
import book from '../../assets/book.png'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import bai from "../../assets/Logo BAI-02.png"
import images from "../../assets/public/images/Images";



function Home() {
    const navigate = useNavigate();
    const location = useLocation()
    const [token,setToken]=React.useState("")
    const [user,setUser]=React.useState("")
     const tokens = localStorage.getItem('Token')
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 768 && window.innerWidth <= 1024);
    const [konten,setKondisi]=React.useState([
        {id:'1',image:trade1,des:"Kenapa trading menggunakan URUZ? Kalau trading di URUZ, Anda dapat menganalisis dari berbagai provider ternama untuk mendapatkan laporan pergerakan harga, berita pasar terkini, serta informasi penting lainnya yang Anda butuhkan ketika bertransaksi. Salah satu tools unggulan kami adalah Trading Signal yang memberikan informasi potensi pergerakan harga untuk produk-produk tertentu.",judul:"Kemudahan Bertransaksi dengan URUZ"},
        {id:'2',image:trade2,des:"Banyak orang ingin trading forex, tetapi kesulitan memahami dasar-dasar dan cara trading forex. Oleh karena itu, URUZ menghadirkan platform edukasi interaktif berbasis online untuk membantu Anda belajar trading. Konten edukasi dibuat ringan dan singkat sehingga seorang trader pemula bisa belajar trading dari nol dalam waktu 1 jam.",judul:"Platform yang Ramah untuk Trader Pemula"},
        {id:'3',image:trade3,des:"Setiap kali Anda bertransaksi di URUZ, Anda mendapatkan poin URUZ Rewards yang dapat ditukarkan dengan berbagai hadiah travel, gadget, lifestyle, maupun otomotif.",judul:"Keuntungan Tambahan Trading di URUZ"}

    ])
    const [keuntungan,setKeuntungan]=React.useState([{name:"Pembukaan Mudah",image:images.easy},{name:"Layanan Bantuan",image:images.customer},{name:"Relationship Manager",image:images.publics},{name:"Comunnity Gathering",image:images.partner}])

    const handleDragStart = (e) => e.preventDefault();

    const items = [
    <div style={{width:'650px'}}>
        <img src={trade1} onDragStart={handleDragStart} role="presentation" style={{width:'600px',height:'400px'}}/>
    </div>,
    <div style={{width:'650px'}}>
        <img src={trade2} onDragStart={handleDragStart} role="presentation" style={{width:'600px',height:'400px'}}/>
    </div>,
   <div style={{width:'650px'}}>
        <img src={trade1} onDragStart={handleDragStart} role="presentation" style={{width:'600px',height:'400px'}}/>
    </div>,
    <div style={{width:'650px'}}>
        <img src={trade2} onDragStart={handleDragStart} role="presentation" style={{width:'600px',height:'400px'}}/>
    </div>,
    <div style={{width:'650px'}}>
        <img src={trade1} onDragStart={handleDragStart} role="presentation" style={{width:'600px',height:'400px'}}/>
    </div>,
    ];

    React.useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width <= 768);
            setIsTablet(width > 768 && width <= 1024);
            console.log(isMobile,isTablet)
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    React.useEffect(() => {
        if(location.pathname=="/"){
            if(tokens?.length>0){
                localStorage.setItem("dashboard","")
                console.log("masuk sini gak")
            }
        }
    }, []);


    return (
        <div className="App22">
            <Navbar />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Audiowide"></link>
            <header className="App-header">
                <div style={{ alignItems: 'center', justifyContent: 'flex-start', width: '100%',height:"100%",minHeight:"80vh" }}>
                    <div style={{ display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: '#212429', minHeight: '60vh', width: '100vw', color: 'white', fontSize: 'calc(10px + 2vmin)', paddingBottom: '1vw' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: isMobile || isTablet ? '100%' : '50%', padding: '5%' }}>
                            <text className='text-judul' style={{ fontSize:isMobile||isTablet?'5vh': '7vh',  alignSelf: 'flex-start' }}>Banteng Asset</text>
                            <text className='text-judul' style={{ fontSize:isMobile||isTablet?'5vh': '7vh', alignSelf: 'flex-start' }}>Investama </text>

                            <Gap width={'5vh'} height={'5vh'} />
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', textAlign: 'start' }}>
                                <text className='text-des' style={{ fontSize:isMobile||isTablet?'3.4vh':  '2.5vh' }}>PT Banteng Asset Investama atau biasa disingkat BAI adalah perusahaan Broker Local yang memiliki Winner Trader yang sudah berpengalaman dan sudah Approve.</text>
                            </div>
                            <Gap width={'5vh'} height={'5vh'} />

                        </div>
                        <div style={{ width: isMobile ? '100%' : '50%' }}>
                            <img src={bai} style={{ width: '50%', height: '50%' }} alt="Trading"></img>
                        </div>

                    </div>
                </div>
            </header>
            <div
                style={{
                position: "absolute",
                top: isMobile ? 450 : isTablet ? 300 : 60,
                right: 0,
                zIndex: 2,
                }}
            >
                {/* {!isMobile && !isTablet && (
                <img
                    src={images.inves} // Update this path as needed
                    style={{ width: isMobile ? "200px" : isTablet ? "400px" : "650px" }}
                    alt="Investment Image"
                />
                )} */}
            </div>
            <div
                className="w-full"
                style={{
                padding: "5%",
                }}
            >
                <div
                className="w-full bg-white relative"
                style={{
                    borderRadius:10,
                    top: isMobile ? -30 : -80,
                    zIndex: 1,
                }}
                >

                    <div
                        className="w-full bg-white"
                        style={{
                        padding: "3%",
                        paddingLeft: isMobile ? "10%" : "20%",
                        paddingRight: isMobile ? "10%" : "20%",borderRadius:10
                        }}
                    >
                        <div
                        className="font-bold justify-center items-center w-full text-center text-judul"
                        style={{
                            marginBottom: "3%",
                            fontSize: "32px",color:"black"
                        }}
                        >
                        Keunggulan 
                        </div>
                        <div
                        className="w-full justify-center items-center text-center"
                        style={{
                            marginBottom: "3%",
                            fontSize: isMobile ? "16px" : "20px",textAlign:"center"
                        }}
                        >
                        Keunggulan layanan trading mencakup eksekusi transaksi yang cepat, biaya rendah dengan spread kompetitif, akses ke beragam pilihan aset, serta fitur manajemen risiko seperti stop loss dan take profit. Platform yang intuitif dan dilengkapi alat analisis teknis, ditambah dukungan pelanggan yang responsif, meningkatkan pengalaman trading. Keamanan dana dan informasi pribadi yang tinggi, ditambah dengan materi edukasi dan aplikasi mobile yang memudahkan akses kapan saja, membuat layanan trading semakin unggul dan terpercaya.
                        </div>
                        {/* <div className="flex flex-row w-full justify-between items-center text-center">
                        <div className="flex flex-col items-center text-center">
                            <div style={{ marginTop: "20px" }}>
                            <img
                                src={images.keunggulan1} // Update this path as needed
                                style={{ width: "10vw", height: "100%" }}
                                alt="Keunggulan 1"
                            />
                            </div>
                            <div
                            style={{
                                fontSize: isMobile ? "14px" : "20px",
                                marginTop: "20px",
                            }}
                            >
                            Pembukaan Mudah
                            </div>
                        </div>
                        <div className="flex flex-col items-center text-center">
                            <div
                            className="flex justify-center"
                            style={{ marginTop: "20px" }}
                            >
                            <img
                                src={images.keunggulan2} // Update this path as needed
                                style={{ width: "10vw", height: "100%" }}
                                alt="Keunggulan 2"
                            />
                            </div>
                            <div
                            style={{
                                fontSize: isMobile ? "14px" : "20px",
                                marginTop: "10px",
                            }}
                            >
                            Layanan Bantuan
                            </div>
                        </div>
                        <div className="flex flex-col items-center text-center">
                            <div
                            className="flex justify-center"
                            style={{ marginTop: "20px" }}
                            >
                            <img
                                src={images.keunggulan3} // Update this path as needed
                                style={{ width: "10vw", height: "100%" }}
                                alt="Keunggulan 3"
                            />
                            </div>
                            <div
                            style={{
                                fontSize: isMobile ? "14px" : "20px",
                                marginTop: "10px",
                            }}
                            >
                            Relationship Manager
                            </div>
                        </div>
                        </div> */}
                    </div>

                    <div
                        className="w-full bg-white"
                        style={{
                        padding: "4%",
                        paddingLeft: isMobile ? "10%" : "15%",
                        paddingRight: isMobile ? "10%" : "15%",borderRadius:10
                        }}
                    >
                        <div
                        className="font-bold w-full justify-center items-center text-center text-black text-judul"
                        style={{
                            marginBottom: "3%",
                            fontSize: "32px",
                        }}
                        >
                        {/* Keuntungan  */}
                        </div>
                        <div className="w-full justify-center items-center text-center text-black"></div>
                        <Carousel
                        cols={isTablet ? 2 : 5}
                        rows={1}
                        gap={isMobile || isTablet ? 34 : 10}
                        loop={true}
                        autoPlay={1000}
                        >
                        { keuntungan&&keuntungan.map((items,index)=>{
                            return(
                                <Carousel.Item key={index}>
                                    <div
                                    style={{
                                        display: isMobile || isTablet ? "" : "flex",
                                        flexDirection: isMobile || isTablet ? "row" : "column",
                                        alignItems: "center",
                                    }}
                                    >
                                    <div style={{ marginTop: "20px" }}>
                                        <img
                                        src={items.image} // Update this path as needed
                                        style={{ width: isMobile || isTablet ?"30vw":"14vw" }}
                                        alt={items.name}
                                        />
                                    </div>
                                    <div
                                        className="text-black text-center"
                                        style={{
                                        fontSize: isMobile ? "16px" : "20px",
                                        marginTop: "10px",
                                        }}
                                    >
                                    {items.name}
                                    </div>
                                    </div>
                                </Carousel.Item>
                            )
                        })
                        }
                        </Carousel>
                    </div>
                </div>
                <div className="content-wrapper home-container" style={{ padding:"3%",width:"90vw",backgroundColor:"white",borderRadius:5,justifyContent:"center",alignItems:"center",marginTop:"5%"}}>
                    <div style={{ marginBottom:"2%",display:"flex",justifyContent:"center",alignItems:"center",width:"100%" }}>
                        <text className="text-judul" style={{color:'#2A2626',fontSize:'2.5vh'}}>MEMBER RULES</text>
                    </div>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ color:"#2A2626" }}>Product</th>
                                    <th style={{ color:"#2A2626" }}>Minimal Deposit</th>
                                    <th style={{ color:"#2A2626" }}>Membership Fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Silver</td>
                                    <td style={{ textAlign:"end" }}>100 Million</td>
                                    <td style={{ display:"flex" }}><s>30%</s> <div style={{ color:"#2196F3",marginLeft:"1%" }}>{"(free)"}</div></td>
                                </tr>
                                <tr>
                                    <td>Gold</td>
                                    <td style={{ textAlign:"end" }}>500 Million</td>
                                    <td style={{ display:"flex" }}><s>25%</s> <div style={{ color:"#2196F3",marginLeft:"1%" }}>{"(free)"}</div></td>
                                </tr>
                                <tr>
                                    <td>Platinum</td>
                                    <td style={{ textAlign:"end" }}>1,000 Million</td>
                                     <td style={{ display:"flex" }}><s>20%</s> <div style={{ color:"#2196F3",marginLeft:"1%" }}>{"(free)"}</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="section">
                        <h2>Trading Lot</h2>
                        <p style={{ textAlign:"start" }}>
                            The trading lot is determined based on the member's deposit, with a ratio of 0.1 lot per 100 million of deposit. This ensures that the trading volume aligns with the member's financial capacity.
                        </p>
                    </div>
                    <div className="section">
                        <h2>Membership Fee</h2>
                        <p style={{ textAlign:"start" }}>
                            This fee covers the use of trading algorithms provided by Banteeng Asset Investama. For the first cycle, this fee is waived. Subsequent membership fees are deducted from the member’s withdrawn profits, with the amount depending on the chosen product type.
                        </p>
                    </div>
                    <div className="section">
                        <h2>Profit Expectation</h2>
                        <p style={{ textAlign:"start" }}>
                            This represents the anticipated return a member aims to achieve from trading with the provided algorithms. Trading will cease once the profit reaches the member's specified target.
                        </p>
                    </div>
                    <div className="section">
                        <h2>Risk Management</h2>
                        <p style={{ textAlign:"start" }}>
                            Our risk management framework is designed to safeguard member capital from significant losses by implementing risk limits and effective mitigation strategies throughout the trading process.
                        </p>
                    </div>
                </div>
            </div>
            <Footer isMobile isTablet />
{/* 
            <Footer                 
                isMobile={isMobile}
                isTablet={isTablet}/> */}

        </div>
    );
}

export default Home;
