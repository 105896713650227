import './sideBar.css';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from "../../assets/Logo BAI-02.png"
import autoAnimate from '@formkit/auto-animate'
import { useEffect, useRef } from 'react'
import side from "../../assets/sideBar.png"
import { useNavigate,useLocation} from "react-router-dom";
import user2 from "../../assets/Group 4.png"
import { RectangleGroupIcon,ArrowLeftStartOnRectangleIcon,Cog8ToothIcon,HomeIcon,Square3Stack3DIcon,WalletIcon,BuildingLibraryIcon} from '@heroicons/react/24/solid';


function SideBar({isMobile,isTablet}) {
    const parentRef = useRef(null)
    const location = useLocation();
    const [sidebar,setSideBar]=React.useState(false)
    const [current,setCurrent]=React.useState("")
    const navigate = useNavigate();
    const tab =[
        {id:"/copytrading",path:"copytrading",name:"Copy Trading",icon:<RectangleGroupIcon style={{ width:isMobile||isTablet?"4vh":"3vh",height:isMobile||isTablet?"4vh":"2vh",color:"white",marginRight:isMobile||isTablet?"1vh":"" }}/>},
        {id:"/wallet",path:"wallet",name:"Wallet",icon:<WalletIcon style={{ width:isMobile||isTablet?"4vh":"3vh",height:isMobile||isTablet?"4vh":"2vh",color:"white",marginRight:isMobile||isTablet?"1vh":"" }}/>},
         {id:"/rules",path:"rules",name:"Rules",icon:<Square3Stack3DIcon style={{ width:isMobile||isTablet?"4vh":"3vh",height:isMobile||isTablet?"4vh":"2vh",color:"white",marginRight:isMobile||isTablet?"1vh":"" }}/>},
         {id:"/profile",path:"profile",name:"Setting Profile",icon:<Cog8ToothIcon style={{ width:isMobile||isTablet?"4vh":"3vh",height:isMobile||isTablet?"4vh":"2vh",color:"white",marginRight:isMobile||isTablet?"1vh":"" }}/>},
         {id:"https://www.triveinvest.co.id/",path:"",name:"Broker",icon:<BuildingLibraryIcon style={{ width:isMobile||isTablet?"4vh":"3vh",height:isMobile||isTablet?"4vh":"2vh",color:"white",marginRight:isMobile||isTablet?"1vh":"" }}/>},
        {id:"/logout",path:"",name:"Logout",icon:<ArrowLeftStartOnRectangleIcon style={{ width:isMobile||isTablet?"4vh":"3vh",height:isMobile||isTablet?"4vh":"2vh",color:"white",marginRight:isMobile||isTablet?"1vh":"" }}/>}]
    const tabAdmin =[
        {id:"/usersetting",path:"usersetting",name:"User Setting",icon:<HomeIcon style={{ width:"3vw",height:"2vh",color:"white"}}/>},
        {id:"/logout",path:"",name:"Logout",icon:<ArrowLeftStartOnRectangleIcon style={{ width:isMobile||isTablet?"4vh":"3vh",height:isMobile||isTablet?"4vh":"2vh",color:"white",marginRight:isMobile||isTablet?"1vh":"" }}/>}]
    const [pilihanItems,setPilihanItems]=React.useState("")
    const email = localStorage.getItem('User')
    const user=localStorage.getItem("UserType")

    React.useEffect(()=>{
        console.log(location.pathname)
        setCurrent(location.pathname)
    },[pilihanItems])

    async function onLogout(){
    	localStorage.setItem("Token","")
		  localStorage.setItem("User","")
          localStorage.setItem("dashboard","")
      navigate("/")
    }

        return (
            <div className="sidebar-container" style={{width:isMobile||isTablet?'20%':'15%',boxShadow: '1px 1px 10px 0 rgba(0, 0, 0, 0.1)'}}>         
                <div className="App sidebar-expanded" style={{display:'flex',flexDirection:'column',height:'100vh',boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                    <div style={{width:'100%',display:'flex',justifyContent:'flex-start',alignItems:"center",paddingLeft:"5%",paddingTop:"4%",paddingBottom:"4%"}}>
                        {/* <text className='text-des' style={{fontSize:'2vh',color:'white'}}>General</text> */}
                         <img  src={user2} style={{width:'4vh',height:'4vh',marginRight:"5%"}}></img>
                        <div className='text-des' style={{ color:"white",opacity:0.7 }}>{email?.substring(0,10)}</div>
                    </div>
                    <div style={{ width:"100%",height:"0.1vh",backgroundColor:"#596c75",opacity:0.5}}></div>
                    <div style={{width:'100%',height:'80vh'}}>
                    {
                        user=="user"&&tab?.map((items,index)=>{
                            return(
                                <div onClick={()=>{
                                    if(items.name=="Broker"){
                                        window.location.href = items.id
                                    }
                                    else if(items.name=="Logout"){
                                         onLogout()
                                    }
                                    else if(items.name=="Home"){
                                         navigate(items.id);
                                         localStorage.setItem("dashboard","")
                                    }
                                    else{
                                        navigate(items.id);
                                        localStorage.setItem("dashboard","login")
                                    }
                                    setPilihanItems(items.path)
                                }} key={index} className='hover:overlay' style={{
                                    backgroundColor:current==items.id?"#126bc4":""
                                    ,width:'90%',height:isMobile||isTablet?"10vh":'5vh'
                                    ,display:'flex',justifyContent:'flex-start'
                                    ,alignItems:'center',paddingLeft:'5%',
                                    cursor:"pointer",margin:"5%",borderRadius:5,boxShadow: '1px 1px 10px 0 rgba(0, 0, 0, 0.1)',
                                    }}  >  
                                    {items.icon}
                                    <text className='text-des' style={{ opacity:"0.8" }}>{items.name}</text>
                                </div>
                            )
                        })
                    }
                     {
                        user=="admin"&&tabAdmin?.map((items,index)=>{
                            return(
                                <div onClick={()=>{
                                    if(items.id=="/logout"){
                                        onLogout()
                                    }
                                    else if(items.id=="/"){
                                         navigate(items.id);
                                         localStorage.setItem("dashboard","")
                                    }
                                    else{
                                        navigate(items.id);
                                        localStorage.setItem("dashboard","login")
                                    }
                                    setPilihanItems(items.path)
                                }} key={index} className='hover:overlay' style={{
                                    backgroundColor:current==items.id?"#126bc4":""
                                    ,width:'90%',height:isMobile||isTablet?"10vh":'5vh'
                                    ,display:'flex',justifyContent:'flex-start'
                                    ,alignItems:'center',paddingLeft:'5%',
                                    cursor:"pointer",margin:"5%",borderRadius:5,boxShadow: '1px 1px 10px 0 rgba(0, 0, 0, 0.1)',
                                    }}>
                                    {items.icon}
                                    <text className='text-des' style={{ opacity:"0.8" }}>{items.name}</text>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            </div>
            
        );

}

export default SideBar;
