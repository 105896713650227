import React from 'react'
import { useRef } from 'react';
import UruzB from '../../assets/UruzBlue.png'
import UruzA from '../../assets/UruzWhite.png'
import Gap from '../../component/Gap/Gap';
import Button from '../../component/button/button';
import Input from '../../component/Input/input';
import FETCH_API from '../../config';
import Button2 from '../../component/button/button2';
import image from '../../assets/image6.png'
import "./forget.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useLocation,useNavigate} from 'react-router-dom';


function Home() {
	const windowSize = useRef([window.innerWidth, window.innerHeight]);
	const [data,setData]=React.useState({name:'',email:'',pass:'',pass2:''})
	const location = useLocation();
	const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 768 && window.innerWidth <= 1024);
	const [forget,setForget]=React.useState(false)
	const [token,setToken]=React.useState('')
	const navigate=useNavigate()
	const buttonRef = React.useRef(null);

    const handleClick = () => {
		if (buttonRef.current) {
            // buttonRef.current.classList.remove('animate-click');
            void buttonRef.current.offsetWidth; // Trigger reflow to reset animation
            // buttonRef.current.classList.add('animate-click');
            buttonRef.current.classList.add('color-change');
            setTimeout(() => {
                buttonRef.current.classList.remove('color-change');
            }, 300); // Adjust this timeout to match your animation duration
        }
    };

	React.useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width <= 768);
            setIsTablet(width > 768 && width <= 1024);
        };
		console.log(window.location.href)
		
		const getParameterByName = (name, url = window.location.href) => {
			name = name.replace(/[\[\]]/g, '\\$&');
			var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
				results = regex.exec(url);
			if (!results) return null;
			if (!results[2]) return '';
			return decodeURIComponent(results[2].replace(/\+/g, ' '));
		 }

		console.log(getParameterByName("token"))
		setToken(getParameterByName("token"))
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowSize]);



	async function passwordChange(){
		console.log(data.pass)
		if(data.pass!=data.pass2){
				toast.error("Password Tidak Sama")
				return
		}
		if (!(/[A-Z]/.test(data.pass))) {
				toast.error("Password Harus Berisi Huruf Kapital")
				return
		}
		if (!(/[a-z]/.test(data.pass))) {
				toast.error("Password Harus Berisi Huruf Kecil")
				return
		}
		if (!(/\d/.test(data.pass))) {
				toast.error("Password Harus Berisi Angka")
				return
		}
		if (data.pass?.length<=6) {
				toast.error("Password Harus Lebih dari 6 Huruf")
				return
		}
		try {
			const login = await FETCH_API("users/reset/password?token="+token, {
				// "id":data.email,
				"password": data.pass,
			})
			console.log(login)
			if(login.status=="OK"){
				toast.success(login.message)
				navigate("/?param=reset");
			}
			else{
				if(login.message!==undefined){
					toast.error(login.message)
				}
				else{
					toast.error(login.error.message)
				}
			}
		} catch (error) {
			toast.error(error)
		}
	}

	


    return(

        <div className='main-css'>
		<div style={{display:'flex',flexDirection:'row',width:'100%'}}>
			{
			<div style={{display:'flex',width:isMobile || isTablet ?'100%':'50%',justifyContent:'center',alignItems:'center',height:'100vh',overflow:'hidden'}}>
				<div style={{ width:'100%',height:'100vh',paddingTop:'10%'}}>
				<img src={UruzB} style={{width:isMobile || isTablet ?'100px':'10vw', margin:'auto',marginLeft:'5%',marginBottom:'2%'}} ></img>
				<div style={{paddingLeft:isMobile || isTablet ?'10%':'20%',paddingRight:isMobile || isTablet ?'10%':'20%'}}>
					<div style={{ display: 'grid', alignItems: 'center',marginBottom:'3%' }}>
						{
							location.state === 'regis'?
							<div className="text-judul"  style={{ fontSize:isMobile || isTablet ?28 : '2.2vw',color:'#344054'}} >Buat Akun Baru</div>
							:
							<div  style={{ fontSize:isMobile || isTablet ?28 : '2.2vw',color:'#344054'}} className="text-judul" >Forget Password</div>

						}
						{/* <div style={{ fontSize: isMobile || isTablet ?18:'1.1vw',color:'#344054',opacity:0.6,fontWeight:'lighter'}} className="text-des" >Please fill your detail to access your account</div> */}
					</div>
				<div
                  style={{

                  }}
                >
					<Gap height={15} />
					{/* <Input type="input" onChange={(val)=>{
						setData((prev) => ({
							...prev,
							email:val.target.value
						}))
					}} placeholder="budi" label="E-mail" id="input1" onKeyDown={(event)=>{
						console.log("isinya apa",event.key)
						if (event.key === "Enter") {
							event.preventDefault();
							document.getElementById("input2").focus();
						}
						}}/> */}
					{/* <Gap height={15} /> */}
					<Input type="password" onChange={(val)=>{
						setData((prev) => ({
							...prev,
							pass:val.target.value
						}))
					}} placeholder="*******" label="Password" id="input2" onKeyDown={(event)=>{
						console.log("isinya apa",event.key)
						if (event.key === "Enter") {
							event.preventDefault();
							document.getElementById("input3").focus();
						}
						}}/>

					<Gap height={15} />
					<Input type="password" onChange={(val)=>{
						setData((prev) => ({
							...prev,
							pass2:val.target.value
						}))
					}} placeholder="*******" label="Repeat Password" id="input3" onKeyDown={(event)=>{
						console.log("isinya apa",event.key)
						if (event.key === "Enter") {
							handleClick();
							passwordChange()
						}
						}}/>
                   
                </div>
				<Gap height={15} />
				<div className='cover-text-must'>
                            <text className='text-des text-must'>* At Least 6 character</text>
                            <text className='text-des text-must'>* At Least 1 Upper Case Letter {"(A-Z)"}</text>
                            <text className='text-des text-must'>* At Least 1 lower Case Letter {"(A-Z)"}</text>
                            <text className='text-des text-must'>* At Least 1 Number {"(0-9)"}</text>
                        </div>
				</div>
				<Gap height={25} />

					{/* <div style={{paddingLeft:isMobile || isTablet ?'10%':'20%',paddingRight:isMobile || isTablet ?'10%':'20%'}}>
						<Button2
							backgroundColor="rgb(51, 80, 160)"
							color2="rgb(51, 80, 160)"
							border='rgb(51, 80, 160)'
							onClick={()=>{passwordChange()}}
						>
							<text className="text-judul"  style={{color:'white',fontWeight:'bold'}}>Submit</text>
						</Button2>
					</div> */}
								<div className='padding-content'>
									<div onClick={()=>{handleClick();passwordChange()}}
										className='background-pass' ref={buttonRef}
										>
											<div style={{display:'flex'}}>
											{/* <img src={Save} style={{width:'2.5vh',height:'2.5vh'}}></img> */}
											{/* <Gap width={'2vh'}></Gap> */}
											<text className='text-judul' style={{color:'white'}}>SUBMIT</text>
											</div>
										</div>
								</div>
			</div>	
			</div>
			}
			{
				!isTablet && !isMobile?
			<div style={{display:'flex',width:'50%',backgroundImage: 'linear-gradient(180deg, rgba(39, 94, 148, 1), rgb(102, 142, 182))',justifyContent:'center',alignItems:'center',height:'100vh'}}>
				<img src={image} style={{width:'32vw'}} ></img>
			</div>
			:null
			}
		</div>
			<ToastContainer />
      </div>
    )
}

export default Home