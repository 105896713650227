
import '../Dashboard/userprofile.css';
import Gap from '../../component/Gap/Gap';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import pc from '../../assets/pc.png'
import Cards2 from '../../component/card/cardStrategy';
import DatePicker from "react-datepicker";
import Modal from '../../component/modal/modal';
import '../AdminSetting/adminSetting.css'
import Empty from '../../component/empty/empty';
import logoCalendar from "../../assets/CalendarBlues.png"
import Refresh from "../../assets/RefreshWhite.png"
import Navbar from '../../component/navbar/navbar';
import { useNavigate } from "react-router-dom";
import trade1 from '../../assets/screen.png'
import trade2 from '../../assets/trade2.png'
import trade3 from '../../assets/trade3.png'
import profilewhite from '../../assets/userGray.png'
import FETCH_API from '../../config';
import { Card, Form, FloatingLabel, Col } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import formatterMoney from '../../component/formatMoney';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { CalendarIcon} from '@heroicons/react/24/outline';
import * as XLSX from "xlsx";
import { prepareImport } from '../../component/excel/prepareImport';
import Input from '../../component/Input/input';
import SideBar from '../../component/sideBar/sideBar';

export default function AdminSetting({
}){
    const fileInputRef = React.createRef();
    const navigate = useNavigate();
    const buttonRefReport = React.useRef(null);
    const [items,setItems]=React.useState()
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 768 && window.innerWidth <= 1024);
    const [dateWallet,setDateWallet]=React.useState(formatDateFirst(new Date()))
    const [date2Wallet,setDate2Wallet]=React.useState(new Date())
    const Id = localStorage.getItem('ID')
    const [search,setSearch]=React.useState()
    const [user,setUser]=React.useState([])
    function formatDateFirst(params) {
        return moment(params).format('YYYY-MM-01')
    }

    React.useEffect(()=>{
        getUser()
    },[])
    
    const handleClick = () => {
		if (buttonRefReport.current) {
            // buttonRef.current.classList.remove('animate-click');
            void buttonRefReport.current.offsetWidth; // Trigger reflow to reset animation
            // buttonRef.current.classList.add('animate-click');
            buttonRefReport.current.classList.add('color-change-report');
            setTimeout(() => {
                buttonRefReport.current.classList.remove('color-change-report');
            }, 300); // Adjust this timeout to match your animation duration
        }
    };

        function formatDate(params) {
        return moment(params).format('MMMM DD, YYYY')
    }

    function dateTime(params){
         return moment(params).format('YYYY-MM-DD HH:mm:ss')
    }

    const importFromExcel = async (evt) => {
        const validations = [
        {
            sheetName: 'Sheet1',
            columns: {
            "DATE": { field: "transaction_time", required: true, defaultValue: '' },
            "CURRENCY": { field: 'currency', required: true, defaultValue: '' },
            "EMAIL": { field: 'email', required: true, defaultValue: '' },
            "AMOUNT": { field: 'amount', required: true, defaultValue: 0 },
            "TYPE": { field: 'transaction_type', required: true, defaultValue: ""},
            "STATUS": { field: 'status', required: true, defaultValue: "" },
            }
        }
        ];

        const results = await prepareImport(evt, validations, 0, 1);

        if (!results) return;
        console.log('results', results);

        let resultsObj = {};
        for (let x = 0; x < results[validations[0]['sheetName']].length; x++) {
        const item = results[validations[0]['sheetName']][x];
        if (!resultsObj[item.transaction_time+item.amount]) {
            resultsObj[item.transaction_time+item.amount] = {
            transaction_time: '',
            currency: '',
            email:"",
            amount:"",
            transaction_type:"",
            status:""
            };
        }
        resultsObj[item.transaction_time+item.amount].transaction_time = dateTime(item.transaction_time);
        resultsObj[item.transaction_time+item.amount].currency = item.currency;
        resultsObj[item.transaction_time+item.amount].email = item.email;
        resultsObj[item.transaction_time+item.amount].amount = item.amount;
        resultsObj[item.transaction_time+item.amount].transaction_type = item.transaction_type;
        resultsObj[item.transaction_time+item.amount].status = item.status;
        }

        const dataToImport = Object.values(resultsObj);
        console.log("isinya apa cuy",dataToImport)
         try {
            const laporan = await FETCH_API("trading/bulk-insert-trading-history", {
                "userId": Id,
                "data":dataToImport,
            },navigate)
            console.log(laporan)
            if(laporan.status === 'OK'){
                toast.success(laporan.message)
                // navigate("/")
            }
            else{
                if(laporan.message!==undefined){
                    toast.error(laporan.message)
                }
                else{
                    toast.error(laporan.error.message)
                }
            }
        } catch (error) {
            toast.error(error)
        }
    };

    async function getUser(params) {
        try {
            const laporan = await FETCH_API("users/get-all", {
                "id": Id,
                "page":1,
                "pageSize": 999
            },navigate)
            console.log("apa isinya",laporan.users)
            if(laporan.status === 'OK'){
                // toast.success(laporan.message)
                if(search?.length>0){
                    let newData=laporan.users.filter(item => (item.username.includes(search)&&item.role!="admin"))
                    setUser(newData)
                }
                else{
                     let newData=laporan.users.filter(item=>item.role!="admin")
                    setUser(newData)
                }
                // navigate("/")
            }
            else{
                if(laporan.message!==undefined){
                    toast.error(laporan.message)
                }
                else{
                    toast.error(laporan.error.message)
                }
            }
        } catch (error) {
            toast.error(error)
        }
        
    }


    return(
        <div style={{ width:"100vw" }}>
            <SideBar isMobile={isMobile} isTablet={isTablet}/>
            <Navbar/>
            <Gap height={70}></Gap>
            {/* <div style={{  marginLeft:"15%",marginBottom:"1%"}}>
                <text className='text-des' style={{ fontSize:"1.2vw",color:"black" }}>USER SETTING</text>
            </div> */}
            <div className='main-profile' style={{ marginLeft:isMobile||isTablet?"23%":"17.5%",width:isMobile||isTablet?"73.5%":"80vw",backgroundColor:"white",borderRadius:5,marginBottom:"10%",boxShadow:"0 0 4px gray"}}>
                <div style={{ width:"100%",height:isMobile||isTablet?"9vh":"6vh",backgroundColor:"#126bc4",borderTopLeftRadius:5,borderTopRightRadius:5,alignItems:"center",display:"flex",paddingLeft:"2%"}}>
                        <text className='text-des' style={{ fontSize:isMobile||isTablet?"1.5vw":"1.2vw",color:"white" }}>ADMIN</text>
                </div>
                <Gap height={"4vh"}/>
                <div style={{width:'100%',height:isMobile||isTablet?"11vh":'8vh',display:'flex',justifyContent:'flex-start',alignItems:'center',paddingLeft:isMobile||isTablet?'2%':'1%'}}>
                            <Gap width={isMobile||isTablet?'10px':'10px'}></Gap>
                            <div style={{width:'20%',marginTop:"0.5%"}}>
                            <Input color={"#2A2626"} type="text" onChange={(val)=>{
                               setSearch(val.target.value)
                            }} placeholder="*******" label="Search" value={search}/>
                            </div>
                            <Gap width={isMobile||isTablet?'10px':'10px'}></Gap>
                            <div onClick={()=>{
                                getUser()
                                // handleClick()
                                // reportWallet('ref')
                                        }} style={{width:isMobile||isTablet?'30%':'5%',marginBottom:5}}  className='robot-center'>
                                            <div ref={buttonRefReport}
                                            style={{ 
                                                backgroundColor: '#343a3d',
                                                borderRadius: '10px',
                                                width: '53px',
                                                height: '54px',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                                            }}
                                            >
                                                <div style={{display:'flex'}}>
                                                    <img src={Refresh} style={{ width:'20px',height:'20px' }}></img>
                                                    {/* <Gap width={"10px"}/>
                                                <text className='text-judul' style={{color:'white'}}>Refresh</text> */}
                                                </div>
                                            </div>
                            </div>
                        
                </div>
                    {/* {
                    user?.length!==0&&
                    <div style={{width:isMobile||isTablet?'100%':'97%',margin:isMobile||isTablet?0:'5%',boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',borderTopLeftRadius:10,borderTopRightRadius:10 }}>
                            <div style={{ width:'100%',height:'48px',backgroundColor:'rgb(48, 48, 48)',borderTopLeftRadius:10,borderTopRightRadius:10,display:'flex'}}>
                                <div style={{ width:'30%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                    <text className='text-judul' style={{ fontSize:'14px' }}>E-mail</text>
                                </div>
                                <div style={{ width:'30%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                    <text className='text-judul' style={{ fontSize:'14px' }}>Phone</text>
                                </div>
                                <div style={{ width:'30%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                    <text className='text-judul' style={{ fontSize:'14px' }}>Action</text>
                                </div>
                            </div>
                            {
                                    user?.map((items,index)=>{
                                        let backgroundColor = index % 2 === 0 ?  'rgb(248, 248, 248)':'rgb(228, 228, 228)';
                                        if(items.role!="admin"){
                                            return(
                                                <div style={{ width:'100%',height:'48px',backgroundColor,display:'flex'}}>
                                                    <div style={{ width:'30%',height:'48px',display:'flex',justifyContent:'center',alignItems:'center'  }}>
                                                        <text className='text-des' style={{ fontSize:isTablet||isMobile?'11px':'14px',color:'rgb(48, 48, 48)' }}>{items?.username}</text>
                                                    </div>
                                                    <div style={{ width:'30%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                                        <text className='text-des' style={{fontSize:(isTablet||isMobile)?'11px':'14px',color:'rgb(48, 48, 48)'}}>{items.telp}</text>
                                                    </div>
                            
                                                    <div style={{ width:'30%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'auto',width:isTablet||isMobile?'80%':'50%',}}>
                                                            <label className="btn btn-flat btn-info" style={{ backgroundColor:"#6AC673",borderColor:"#6AC673",marginTop:"5%" }} onClick={() => {setItems(items)}}>
                                                            <input
                                                                style={{ display: 'none' }}
                                                                type="file"
                                                                ref={fileInputRef}
                                                                onChange={importFromExcel}
                                                                name="file"
                                                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                            />
                                                            <text className='text-des' style={{color:'white',fontSize:(isTablet||isMobile)?'11px':'14px'}}>Import Excel</text>
                                                            </label>
                                                        </div>
                                                        
                                                    </div>

                                                </div>
                                            )
                                        }
                                    })
                                }
                        </div>
                    } */}
                    {
                         user?.length!==0&&
                         <div style={{ padding:"2%",width:"100%",display:"flex",flexDirection:"column" }}>
                            <div style={{ boxShadow:"0 0 1.5px 0 gray",borderRadius:5 }}>
                                <div style={{ display:"flex",width:"100%",backgroundColor:"#ebebeb",borderTopLeftRadius:5,borderTopRightRadius:5}}>
                                    <div className='text-judul' style={{ width:"33%",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw"}}>Email</div>
                                    <div className='text-judul' style={{ width:"33%",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw" }}>Phone</div>
                                    <div className='text-judul' style={{ width:"33%",padding:"1%" ,fontSize:isMobile||isTablet?'1.2vw':"1vw"}}>Action</div>
                                
                                </div>
                                 {
                                    user?.map((items,index)=>{
                                    let backgroundColor = index % 2 === 0 ?  'rgb(248, 248, 248)':'#ebebeb';
                                    return(
                                   <div style={{ display:"flex",width:"100%",backgroundColor:backgroundColor,borderBottomRightRadius:5,borderBottomLeftRadius:5}}>
                                        <div style={{ width:"33%",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw" }}>{items.username}</div>
                                        <div style={{ width:"33%",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw" }}>{items.telp}</div>
                                         <label className=" btn-flat btn-info" style={{ color:"#6AC673",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw" }} onClick={() => {setItems(items)}}>
                                            <input
                                                style={{ display: 'none' }}
                                                type="file"
                                                ref={fileInputRef}
                                                onChange={importFromExcel}
                                                name="file"
                                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            />
                                            <text className='text-des' style={{color:'#6AC673',fontSize:(isTablet||isMobile)?'11px':'14px'}}>Import Excel</text>
                                        </label>
                                
                                    </div>
                                    )
                                    })
                                }
                            </div>
                        </div> 
                    }
                    {
                        user?.length===0&&
                        <Empty data={"User"}></Empty>
                    }
            </div>
            <ToastContainer />
        </div>
    )
}

const styles = {
  container: {
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    marginTop:'5%'
  },
  header: {
    // fontSize: '6vw',
    // marginBottom: '20px',
    // color: '#3498db',
  },
  totalsContainer: {
    backgroundColor: '#F5F9FD',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent:"space-between",
    alignItems: 'center',
  },
  totalItem: {
    flex: 1,
    padding: '10px',
  },
  label: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#333',
  },
  value: {
    fontSize: '1.5rem',
    color: '#2ecc71',
    marginBottom: '20px',
  },
    value2: {
    fontSize: '1.5rem',
    color: '#e74c3c',
    marginBottom: '20px',
  },
  percentageValue: {
    fontSize: '1.5rem',
    color: 'gray',
  },
};