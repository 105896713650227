    import * as XLSX from 'xlsx';
    import {importPreparation} from "./importPreparation.js"

    export async function prepareImport(evt, validations, columnStartToValidate = 0, rowStartValues) {
        try {
            // Step 1: Validate columns and prepare data for import
            console.log('Validating columns');
            
            // Assuming `importPreparation` is a helper function that processes the Excel data
            const dataImport = await importPreparation(validations, evt, columnStartToValidate);
            console.log('Data after preparation', dataImport);

            // Initialize an array to hold column names
            const columns = [];
            for (let i = 0; i < Object.keys(validations).length; i++) {
            columns.push({ name: Object.keys(validations)[i] });
            }
            const columnImport = columns; // Not currently used but initialized

            console.log('Data for import', dataImport);

            // Step 2: Map data based on validation rules
            let finalData = {};
            for (let x = 0; x < validations.length; x++) {
            const item = dataImport[validations[x].sheetName];
            console.log('Current item', item);

            const insertPayload = [];
            const mappingKeyValue = item.mapping;

            for (let i = rowStartValues; i < item.data.length; i++) {
                const row = item.data[i];
                let payload = {};

                for (const property in mappingKeyValue) {
                if (validations[x].columns[property]) {
                    console.log('Processing property', property);

                    let value = row[mappingKeyValue[property]];
                    if (value === undefined) {
                    value = validations[x].columns[property]['defaultValue'];
                    }
                    if (value === 0) {
                    value = value.toString();
                    }

                    payload[validations[x].columns[property]['field']] = value;
                }
                }
                insertPayload.push(payload);
            }
            finalData[validations[x].sheetName] = insertPayload;
            }

            console.log('Final mapped data', finalData);

            return finalData;
        } catch (error) {
            console.error('Error during import', error);


            return false;
        }
    }