import './rules.css'; // Updated CSS file import
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Navbar from '../../component/navbar/navbar';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import trade1 from '../../assets/screen.png'

import trade2 from '../../assets/trade2.png';
import trade3 from '../../assets/trade3.png';
import Gap from '../../component/Gap/Gap';
import SideBar from '../../component/sideBar/sideBar';

function Home() {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isTablet, setIsTablet] = useState(window.innerWidth > 768 && window.innerWidth <= 1024);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width <= 768);
            setIsTablet(width > 768 && width <= 1024);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{ width:"100vw"}}>
            <SideBar isMobile={isMobile} isTablet={isTablet}/>
            <Navbar/>
            <Gap height={70}></Gap>
            {/* <div style={{  marginLeft:"15%",marginBottom:"1%"}}>
                <text className='text-des' style={{ fontSize:"1.2vw",color:"black" }}>USER RULES</text>
            </div> */}
            <div style={{ marginBottom:"5%",marginLeft:isTablet||isMobile?"23%":"17.5%",width:isMobile||isTablet?"73vw":"80vw",backgroundColor:"white",borderRadius:5,justifyContent:"center",alignItems:"center",boxShadow:"0 0 4px gray"}}>
                <div style={{ width:"100%",height:isTablet||isMobile?"9vh":"6vh",backgroundColor:"#126bc4",borderTopLeftRadius:5,borderTopRightRadius:5,alignItems:"center",display:"flex",paddingLeft:"2%"}}>
                    <text className='text-des' style={{ fontSize:isMobile||isTablet?"1.7vw":"1.2vw",color:"white" }}>RULES</text>
                </div>
                <div style={{ padding:"1%" }}>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th style={{ color:"#343a3d" }}>Product</th>
                                <th style={{ color:"#343a3d" }}>Minimal Deposit</th>
                                <th style={{ color:"#343a3d" }}>Membership Fee</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Silver</td>
                                <td style={{ textAlign:"end" }}>100 Million</td>
                                <td>30%</td>
                            </tr>
                            <tr>
                                <td>Gold</td>
                                <td style={{ textAlign:"end" }}>500 Million</td>
                                <td>25%</td>
                            </tr>
                            <tr>
                                <td>Platinum</td>
                                <td style={{ textAlign:"end" }}>1,000 Million</td>
                                <td>20%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="section">
                    <h2>Trading Lot</h2>
                    <p>
                        The trading lot is determined based on the member's deposit, with a ratio of 0.1 lot per 100 million of deposit. This ensures that the trading volume aligns with the member's financial capacity.
                    </p>
                </div>
                <div className="section">
                    <h2>Membership Fee</h2>
                    <p>
                        This fee covers the use of trading algorithms provided by Banteeng Asset Investama. For the first cycle, this fee is waived. Subsequent membership fees are deducted from the member’s withdrawn profits, with the amount depending on the chosen product type.
                    </p>
                </div>
                <div className="section">
                    <h2>Profit Expectation</h2>
                    <p>
                        This represents the anticipated return a member aims to achieve from trading with the provided algorithms. Trading will cease once the profit reaches the member's specified target.
                    </p>
                </div>
                <div className="section">
                    <h2>Risk Management</h2>
                    <p>
                        Our risk management framework is designed to safeguard member capital from significant losses by implementing risk limits and effective mitigation strategies throughout the trading process.
                    </p>
                </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
