// Navbar.jsx

import React from "react";
import { Link } from "react-router-dom";
import './navbar.css'
import Logo from '../../assets/UruzWhite.png'
import Button from "../button/button";
import { useNavigate,useLocation } from "react-router-dom";
import Gap from "../Gap/Gap";
import user2 from "../../assets/Group 4.png"
import Strategy from "../../assets/Logout2.png"
import Logout from "../../assets/Strategy.png"
import Home from "../../assets/Home.png"
import Ham from "../../assets/hamburger.png"
import LogoutWhite from "../../assets/LogoutWhite.png"
import HomeWhite from "../../assets/HomeWhite.png"
import StrategyWhite from "../../assets/StrategyWhite.png"
import Dashboard from "../../assets/Dashboard.png"
import bai from "../../assets/bai-logo.png"
import rule from "../../assets/CodeWhite.png"
import trading from "../../assets/komisiWhite.png"
import { RectangleGroupIcon,ArrowLeftStartOnRectangleIcon,Cog8ToothIcon,HomeIcon,Square3Stack3DIcon,WalletIcon} from '@heroicons/react/24/solid';
import images from "../../assets/public/images/Images";

export default function Navbar({}) {

  const navigate = useNavigate();
  const location = useLocation();
  const [setting,setSetting]=React.useState(false)
    const [token,setToken]=React.useState("")
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 768 && window.innerWidth <= 1024);
    const [user,setUser]=React.useState('')
    const [userType,setUserType]=React.useState('')
     let getTokens=localStorage.getItem("Token")
     let dashboard=localStorage.getItem("dashboard")

    React.useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width <= 768);
            setIsTablet(width > 768 && width <= 1024);
            console.log(isMobile,isTablet)
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

      React.useEffect(()=>{
        let getToken=localStorage.getItem("Token")
        let getUser=localStorage.getItem("User")
        let userType=localStorage.getItem("UserType")
        console.log("tokennya",getToken)
        setToken(getToken)
        setUser(getUser)
        setUserType(userType)
    },[])

    async function onLogout(){
    	localStorage.setItem("Token","")
		  localStorage.setItem("User","")
      localStorage.setItem("dashboard","")
      navigate("/")
      setToken("")
    }

  return (
    <nav style={{boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',backgroundColor:dashboard=="login"?"white":"#282c34",height:"5vh"}} onMouseLeave={()=>{setSetting(false)}}>
      <div className='content1' style={{width:isMobile||isTablet?'20%':'60%'}}>
      {dashboard!=="login"&&
        <div className="logo" onClick={()=>{navigate("/");localStorage.setItem("dashboard","")}} >
        <Gap width={40}/>
          <img style={{ width: (isMobile||isTablet)?'20%':'4%' }} src={bai}></img>
          <Gap width={10}/>
          <text style={{ color: '#bdbdbd', fontWeight: 'bold' }}>BAI</text>
        </div>
      }
      {dashboard=="login"&&!(isMobile||isTablet)&&
      <>       
       <div style={{width:"15.5vw", }}>   
        </div>
        <text style={{ color:"#1f1f1f",textAlign:"end",fontSize:"1.7vh" }}>
        {
          location?.pathname=="/profile"?"Setting Profile":location?.pathname=="/wallet"?"User Wallet":location?.pathname=="/copytrading"?"Copy Trading":location?.pathname=="/rules"?"User Rules":location?.pathname=="/usersetting"?"User Setting":location?.pathname=="/deposit"?"Deposit":""
        }</text>
        </>

      }
      </div>
      {getTokens?.length==0?
        <div className='content2' style={{width:isMobile||isTablet?'80%':'40%',padding:isMobile||isTablet?'0.5vh':0}}>
          <div style={{ cursor:"pointer"  }}>
            <div
              onClick={() => { navigate("/login", { state: "login" });localStorage.setItem("dashboard","") }}
            >
              <text style={{ color: 'white', fontWeight: 'bold' }}>Login</text>
            </div>
          </div>
          <div style={{padding:"2%",display:"flex",justifyContent:"center"}}>
            <div style={{backgroundColor:"white",height:"2vh",width:"0.3vh" }}></div>
          </div>
          <div style={{ cursor:"pointer" }}>
            <div
              onClick={() => { navigate("/Regis", { state: "regis" });localStorage.setItem("dashboard","") }}

            >
              <text style={{ color: 'white', fontWeight: 'bold' }}>Daftar</text>
            </div>
          </div>
        </div>
      :
        <div className='content2' style={{width:isMobile||isTablet?'80%':'40%',padding:isMobile||isTablet?'0.5vh':0}}>
        { dashboard!="login"?
              <div
              onClick={() => { 
                if(userType=="admin"){
                  navigate("/usersetting");
                }else{
                  navigate("/copytrading");
                }
                localStorage.setItem("dashboard","login") 
                }}
            >
              <text style={{ color: 'white', fontWeight: 'bold',cursor:"pointer" }}>Dashboard</text>
            </div>
            :
            <>
            <div onClick={()=>{navigate("/");localStorage.setItem("dashboard","")}}> 
                <HomeIcon style={{width:isMobile||isTablet?"4vh":"2vh",height:isMobile||isTablet?"4vh":"2vh",cursor:"pointer"}}/>
            </div>
            <Gap width={"1vw"}/>
            <div onClick={()=>{onLogout()}}> 
                <ArrowLeftStartOnRectangleIcon style={{width:isMobile||isTablet?"4vh":"2vh",height:isMobile||isTablet?"4vh":"2vh",cursor:"pointer"}}/>
            </div>
            </>
        }
          {/* <div style={{width:'100%',display:'flex',justifyContent:'flex-end',alignItems:'center',marginRight:'2%'}}>
             <img onMouseEnter={()=>{setSetting(true)}} onClick={()=>{setSetting(!setting)}} src={user2} style={{width:'3vh',height:'3vh'}}></img>        
          </div>
          {
          setting==true&&
            <div onMouseLeave={()=>{setSetting(!setting)}} style={{position:'absolute',backgroundColor:'white',width:'20vh',height:userType!=="admin"?'32vh':"24vh",top:'4.7vh',borderRadius:10,padding:'1%',right:'1.8vw'}}>
            <div onClick={()=>{navigate("/");localStorage.setItem("dashboard","")}} className="button-fix">
              <HomeIcon style={{ width:"2vh" }}></HomeIcon>
              <Gap width={'10px'} />
              <div style={{ color:"#1f1f1f" }}>Home</div>
            </div>
            {userType!=="admin"?
            <>
            <Gap height={'30px'} />
            <div onClick={()=>{navigate("/profile");localStorage.setItem("dashboard","login")}} className="button-fix">
              <Cog8ToothIcon style={{ width:"2vh" }}></Cog8ToothIcon>
              <Gap width={'10px'} />
              <div style={{ color:"#1f1f1f" }} >Setting</div>
            </div>
            <Gap height={'30px'} />
            <div onClick={()=>{navigate("/wallet");localStorage.setItem("dashboard","login")}} className="button-fix">
              <WalletIcon style={{ width:"2vh" }}></WalletIcon>
              <Gap width={'10px'} />
              <div style={{ color:"#1f1f1f" }} >Wallet</div>
            </div>
            <Gap height={'30px'} />
            <div onClick={()=>{navigate("/copytrading");localStorage.setItem("dashboard","login")}} className="button-fix">
              <RectangleGroupIcon style={{ width:"2vh" }}></RectangleGroupIcon>
              <Gap width={'10px'} />
              <div style={{ color:"#1f1f1f" }} >Copy Trading</div>
            </div>
            </>
            :
            <>
            <Gap height={'30px'} />
            <div onClick={()=>{navigate("/usersetting");localStorage.setItem("dashboard","login")}} className="button-fix">
              <RectangleGroupIcon style={{ width:"2vh" }}></RectangleGroupIcon>
              <Gap width={'10px'} />
              <div style={{ color:"#1f1f1f" }} >User Setting</div>
            </div>
            <Gap height={'30px'} />
            <div onClick={()=>{navigate("/userwithdraw");localStorage.setItem("dashboard","login")}} className="button-fix">
              <WalletIcon style={{ width:"2vh" }}></WalletIcon>
              <Gap width={'10px'} />
              <div style={{ color:"#1f1f1f" }} >User Withdraw</div>
            </div>
            </>
            }
            <Gap height={'30px'} />
            <div onClick={()=>{onLogout()}} className="button-fix">
              <ArrowLeftStartOnRectangleIcon style={{ width:"2vh" }}></ArrowLeftStartOnRectangleIcon>
              <Gap width={'10px'} />
              <div style={{ color:"#1f1f1f" }}>Logout</div>
            </div>
            </div>
          } */}
        </div>
      }
    </nav>
  );
};

