
import '../Dashboard/userprofile.css';
import '../profile/profile.css'
import Gap from '../../component/Gap/Gap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Input from '../../component/Input/input';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, onAuthStateChanged} from "firebase/auth";
import { baseURL } from '../../Constant';
import { useNavigate } from "react-router-dom";
import React from 'react';
import FETCH_API from '../../config';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import user2 from "../../assets/Group 4.png"
import shareIG from "../../assets/ShareIG.png"
import shareFB from "../../assets/ShareFB.png"
import shareWA from "../../assets/ShareWA.png"
import Navbar from '../../component/navbar/navbar';
import Cards from '../../component/card/cardStrategy';
import pc from '../../assets/pc.png'
import DropDown from "../../assets/Dropdown.png"
import LoadingSpinner from '../../component/loading/loading';
import SideBar from "../../component/sideBar/sideBar"
import { getUnit } from '@mui/material/styles/cssUtils';
import trade1 from '../../assets/screen.png'


export default function Profil({}){
const [loading,setLoading]=React.useState(false)
	const [timeOut, setTimeOut] = React.useState(0)
	const [captchaCalled, setCaptchaCalled] = React.useState(false)
	const [recaptcha, setRecaptcha] = React.useState()
    const [verif,setVerif]=React.useState(false)
    const buttonRef = React.useRef(null);
        const navigate = useNavigate();
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 768 && window.innerWidth <= 1024);
    const [date,setDate]=React.useState(formatDatePayload(new Date()))
    const [date2,setDate2]=React.useState(new Date())
    const [dateLRB,setDateLRB]=React.useState(formatDateFirst(new Date()))
    const [date2LRB,setDate2LRB]=React.useState(new Date())
    const [dateLK,setDateLK]=React.useState(formatDateFirst(new Date()))
    const [date2LK,setDate2LK]=React.useState(new Date())
    const [dateWallet,setDateWallet]=React.useState(formatDateFirst(new Date()))
    const [date2Wallet,setDate2Wallet]=React.useState(new Date())
    const [dataUpdate,setDataUpdate]=React.useState({name:"",user:"",telp:"",verifEmail:"",verifTelp:"",strategyId:"",duration:0,metatraderUsername:"",metatraderPassword:"",profit_expectation:0,risk_management:0})
    const [dataStrategy,setDataStrategy]=React.useState({user:"",telp:"",verifEmail:"",verifTelp:""})
    const [dataUser,setDataUser]=React.useState({laba:0,komisi:0,user:[],strategy:''})
    const Id = localStorage.getItem('ID')
    const [pilihDurasi,setPilihDurasi]=React.useState("Durasi")
    function formateDate(params) {
        return moment(params).format('dddd,DD MM YYYY')
    }
    const [tampungindex, setTampungindex] = React.useState(0);
    const [pilihanItems, setPilihanItems] = React.useState(1);

    const handleCardClick = (items, clickedIndex) => {
        setTampungindex(clickedIndex);
        setPilihanItems(items)
        setDataUpdate((prev) => ({
			...prev,
			stategyId:items.id
		}))
    };
    const [edit,setEdit]=React.useState(false)
    const [pageLaba,setPageLaba]=React.useState(0)
    const [halamanLaba,setHalamanLaba]=React.useState([])
    const [pageKomisi,setPageKomisi]=React.useState(0)
    const [halamanKomisi,setHalamanKomisi]=React.useState([])
    const [pageWallet,setPageWallet]=React.useState(0)
    const [halamanWallet,setHalamanWallet]=React.useState([])
    const [data, setData] = React.useState('');

    	async function simpanDurasi(text,jumlah) {
		setPilihDurasi(text)
		setDataUpdate((prev) => ({
			...prev,
			duration:jumlah*30
		}))
	}
        const [dataChoose, setDataChoose] = React.useState([
        {
            "id": "Silver",
            "name": "Minimal Depo 100 Million",
            "duration": 1
        },
        {
            "id": "Gold",
            "name": "Minimal Depo 500 Million",
            "duration": 1
        },
		{
            "id": "Platinum",
            "name": "Minimal Depo 1 Billion",
            "duration": 1
        },

    ],)
    function formatDatePayload(params) {
        return moment(params).format('YYYY-MM-DD')
    }
        function formatDateFirst(params) {
        return moment(params).format('YYYY-MM-01')
    }

    const [tampungindex2, setTampungindex2] = React.useState(0);
    const [pilihanItems2, setPilihanItems2] = React.useState({});
    // console.log(pilihanItems)
    const handleCardClick2 = (items, clickedIndex) => {
        setTampungindex2(clickedIndex);
        setPilihanItems2(items)
    };

    const [trade,setTrade]=React.useState([])
    const [trade22,setDate22]=React.useState([])
    const [trade222,setTrade222]=React.useState()
    const [data2, setData2] = React.useState()


    React.useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width <= 768);
            setIsTablet(width > 768 && width <= 1024);
            console.log(isMobile, isTablet)
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    
    React.useEffect(() => {
        getuser()
        getuserStrategy()
    }, []);

    React.useEffect(() => {
        getParam()
    }, [window.location.href,date,date2,date2LK,date2LRB,date2Wallet,dateLK,dateLRB,dateWallet,pageLaba,pageKomisi,pageWallet]);

    async function getParam(){
		const getParameterByName = (name, url = window.location.href) => {
			name = name.replace(/[\[\]]/g, '\\$&');
			var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
				results = regex.exec(url);
			if (!results) return null;
			if (!results[2]) return '';
			return decodeURIComponent(results[2].replace(/\+/g, ' '));
		 }
		console.log(getParameterByName("id"))
        let id=getParameterByName("id")
        if(id=="Setting"){
            strategy()
            setPilihanItems(1)
        }
        if(id=="Laporan"){
            getLaporanLabaRugi()
            getLaporanKomisi()
            setPilihanItems(2)
        }
        if(id=="Wallet"){
            reportWallet()
            setPilihanItems(3)
        }
        if(id=="Profile"||id=="My Profile"){
            setPilihanItems(4)
        }
        if(id=="Change Password"){
            setPilihanItems(5)
        }
        if(id=="deposit"){
            setPilihanItems(7)
        }
                if(id=="withdraw"){
            setPilihanItems(8)
        }
    }

    async function getuser() {
        try {
            setLoading(true)
            const getuser = await FETCH_API("users/get1", {
                "id": Id,
            },navigate)
            console.log(getuser)
            if (getuser.status === 'OK') {
                // toast.success(getuser.message)
                // setData(getuser.data[0])
                setDataUser((prev) => ({
                    ...prev,
                    laba:getuser.labaRugi,
                    komisi:getuser.komisi,
                    user:getuser.user
                }))
                setDataUpdate((prev) => ({
                        ...prev,
                        user:getuser.user[0].username,
                        telp:parseInt(getuser.user[0].telp),
                        metatraderUsername:getuser.user[0].metatrader_username,
                        risk_management:getuser.user[0].risk_management,
                         profit_expectation:getuser.user[0].profit_expectation,
                         name:getuser?.user[0]?.name,
                    }))
                    setLoading(false)
            }
            else{
				if(getuser.message!==undefined){
					toast.error(getuser.message)
				}
				else{
					toast.error(getuser.error.message)
				}
                setLoading(false)
			}
        } catch (error) {
            console.log(error)
            toast.error(error)
            setLoading(false)
        }
    }

    async function getuserStrategy() {
        try {
            setLoading(true)
            const getuser = await FETCH_API("strategy/get/user", {
                "userId": Id,
                // "date":formatDatePayload(new Date())
            },navigate)
            console.log(getuser)
            if (getuser.status === 'OK') {
                // toast.success(getuser.message)
                setData(getuser.data[0])
                setDataUser((prev) => ({
                    ...prev,
                    strategy:getuser.data.strategy_id,
                    data_strategy:{
                        komisi:getuser.data.commission,
                        deskripsi:getuser.data.description,
                        from:getuser.data.from,
                        to:getuser.data.to
                    }
                }))
                setLoading(false)
            }
            else{
				if(getuser.message!==undefined){
					toast.error(getuser.message)
				}
				else{
					toast.error(getuser.error.message)
				}
                setLoading(false)
			}
        } catch (error) {
            console.log(error)
            toast.error(error)
            setLoading(false)
        }
    }


    async function strategy() {
        try {
            setLoading(true)
            const strategy = await FETCH_API("strategy", {
                "userId": Id,
                "page": 0,
                "row": 3,
                "filter": {
                    "search": "",
                    "sort_col": "id",
                    "sort_by": "asc"
                }
            },navigate)
            console.log(strategy)
            if(strategy.status === 'OK'){
                // toast.success(strategy.message)
                setData2(strategy.data)
                setLoading(false)
            }
            else{
                if(strategy.message!==undefined){
                toast.error(strategy.message)
                }
                else{
                toast.error(strategy.error.message)
                }
                setLoading(false)
            }
            
        } catch (error) {
            toast.error(error)
            setLoading(false)
        }

    }

    async function getLaporanLabaRugi(ref=""){
        try {
            const laporan = await FETCH_API("report/lr", {
                "userId": Id,
                "page": pageLaba,
                "row": 50,
                "from": formatDatePayload(dateLRB),
                "to": formatDatePayload(date2LRB)
            },navigate)
            console.log(laporan)
            if(laporan.status === 'OK'){
                if(ref=="ref"){
                    if(laporan.data.length>0){
                        toast.success(laporan.message)
                    }
                    else{
                        toast.info(`Data dari Tanggal ${formatDatePayload(dateWallet)} sampai ${formatDatePayload(date2Wallet)} Kosong`)
                    }
                }
                // toast.success(laporan.message)
                setTrade(laporan.data)
                let total=laporan.total
                let jumlah=total/50
                let halaman= Math.ceil(jumlah)
                let datanya=[]
                for(var a=0;a<halaman;a++){
                    datanya.push(a+1)
                }
                console.log("datanya",datanya)
                setHalamanLaba(datanya)
                // navigate("/")
            }
            else{
                if(laporan.message!==undefined){
                toast.error(laporan.message)
                }
                else{
                toast.error(laporan.error.message)
                }
            }
        } catch (error) {
            toast.error(error)
        }
    }
    
    async function getLaporanKomisi(ref=""){
        try {
            const laporan = await FETCH_API("report/komisi", {
                "userId": Id,
                "page": pageKomisi,
                "row": 50,
                "from": formatDatePayload(dateLK),
                "to": formatDatePayload(date2LK)
            },navigate)
            console.log(laporan)
            if(laporan.status === 'OK'){
                // toast.success(laporan.message)
                if(ref=="ref"){
                    if(laporan.data.length>0){
                        toast.success("Berhasil Ambil data Report Komisi")
                    }
                    else{
                        toast.info(`Data dari Tanggal ${formatDatePayload(dateWallet)} sampai ${formatDatePayload(date2Wallet)} Kosong`)
                    }
                }
                setDate22(laporan.data)
                let total=laporan.total
                let jumlah=total/50
                let halaman= Math.ceil(jumlah)
                let datanya=[]
                for(var a=0;a<halaman;a++){
                    datanya.push(a+1)
                }
                console.log("datanya",datanya)
                setHalamanKomisi(datanya)
                // navigate("/")
            }
            else{
                if(laporan.message!==undefined){
                toast.error(laporan.message)
                }
                else{
                toast.error(laporan.error.message)
                }
            }
        } catch (error) {
            toast.error(error)
        }
    }

    async function updateUser(){
        try {
            setLoading(true)
            let body={
                "id": Id,
                "username": dataUpdate.user,
                "telp": dataUpdate.telp,
                "metatraderUsername": dataUpdate.metatraderUsername,
                "metatraderPassword":dataUpdate.metatraderPassword,
                "profit_expectation":dataUpdate.profit_expectation,
                "risk_management":dataUpdate.risk_management,
                "name":dataUpdate.name
            }
            if(dataUpdate.user!=dataUser?.user[0]?.username){
                body.codeEmailVerification= dataUpdate.verifEmail
            }
            if(dataUpdate.strategyId.length>0){
                body.strategyId=dataUpdate.strategyId
                body.strategyDuration=dataUpdate.duration
            }
            const laporan = await FETCH_API("users/update", body,navigate)
            console.log(laporan)
            if(laporan.status === 'OK'){
                toast.success("Berhasil update data anda")
                setEdit(false)
                getuser()
                getuserStrategy()
            }
            else{
                if(laporan.message!==undefined){
                toast.error(laporan.message)
                }
                else{
                toast.error(laporan.error.message)
                }
            }
            setLoading(false)
        } catch (error) {
            toast.error(error)
            setLoading(false)
        }
    }

    async function verifEmail(){
        if(dataUpdate.user!=dataUser?.user[0]?.username){
            try {
                setLoading(true)
                const laporan = await FETCH_API("users/save/verification", {
                    "username": dataUpdate.user,
                    "subject": "Email Verification"
                },navigate)
                console.log(laporan)
                if(laporan.status === 'OK'){
                    toast.success(laporan.message)
                    // navigate("/")
                }
                else{
                    if(laporan.message!==undefined){
                    toast.error(laporan.message)
                    }
                    else{
                    toast.error(laporan.error.message)
                    }
                }
                setLoading(false)
            } catch (error) {
                toast.error(error)
                setLoading(false)
            }
        }
        else{
             toast.error("email tidak boleh sama")
             setLoading(false)
        }
    }

    async function reportWallet(ref=""){
         try {
            const laporan = await FETCH_API("report/wallet", {
                "userId": Id,
                "page": pageWallet,
                "row": 50,
                "from": formatDatePayload(dateWallet),
                "to": formatDatePayload(date2Wallet)
            },navigate)
            console.log(laporan)
            if(laporan.status === 'OK'){
                if(ref=="ref"){
                    if(laporan.data.length>0){
                        toast.success(laporan.message)
                    }
                    else{
                        toast.info(`Data dari Tanggal ${formatDatePayload(dateWallet)} sampai ${formatDatePayload(date2Wallet)} Kosong`)
                    }
                }
                // toast.success(laporan.message)
                setTrade222(laporan.data)
                let total=laporan.total
                let jumlah=total/50
                let halaman= Math.ceil(jumlah)
                let datanya=[]
                for(var a=0;a<halaman;a++){
                    datanya.push(a+1)
                }
                console.log("datanya",datanya)
                setHalamanWallet(datanya)
                // navigate("/")
            }
            else{
                if(laporan.message!==undefined){
                toast.error(laporan.message)
                }
                else{
                toast.error(laporan.error.message)
                }
            }
        } catch (error) {
            toast.error(error)
        }
    }

    const handleClick = () => {
		if (buttonRef.current) {
            // buttonRef.current.classList.remove('animate-click');
            void buttonRef.current.offsetWidth; // Trigger reflow to reset animation
            // buttonRef.current.classList.add('animate-click');
            buttonRef.current.classList.add('color-change-profile');
            setTimeout(() => {
                buttonRef.current.classList.remove('color-change-profile');
            }, 300); // Adjust this timeout to match your animation duration
        }
    };

    function configureCaptcha(){
		const auth = getAuth();
		window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
			'size': 'invisible',
			'callback': (response) => {
				// console.log("response",response)
			// reCAPTCHA solved, allow signInWithPhoneNumber.
			setCaptchaCalled(true)
			setRecaptcha(response)
			// sendOTP();
			}
		}, auth)
	}


	function startTimer(duration) {
		let timer = duration, minutes, seconds;
		let myVar = setInterval(function () {
			minutes = parseInt(timer / 60, 10);
			seconds = parseInt(timer % 60, 10);
	
			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;
	
			let date = seconds;
			if (date == '00') {
				clearInterval(myVar);
				setTimeOut(0)
                // setLoadingOTP(false)
				// localStorage.removeItem("timeout");
			}
			else {
				setTimeOut(date)
				// localStorage.setItem('timeout', parseInt(timer))
			}
			if (--timer < 0) {
				timer = duration;
			}
		}, 1000);
	}


    function formatNumber(num) {
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(3).replace(/\.?0+$/, '') + 'B';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(3).replace(/\.?0+$/, '') + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(3).replace(/\.?0+$/, '') + 'K';
    }
    return num?.toString();
    }


    return(
        <div style={{ width:"100vw" }}>
            {loading==true&&
                <LoadingSpinner/>
            }
            <SideBar isMobile={isMobile} isTablet={isTablet}/>
            <Navbar/>
            <Gap height={70}></Gap>
            {/* <div style={{  marginLeft:"15%",marginBottom:"1%"}}>
                <text className='text-des' style={{ fontSize:"1.2vw",color:"black" }}>USER PROFILE</text>
            </div> */}
            {!edit&&
             <div style={{borderRadius:5,boxShadow:"0 0 4px gray",marginLeft:isMobile||isTablet?"23%":"17.5%",width:isMobile||isTablet?"60vw":"45vw"}}>
                <div style={{ width:"100%",height:isMobile||isTablet?"9vh":"6vh",backgroundColor:"#126bc4",borderTopLeftRadius:5,borderTopRightRadius:5,alignItems:"center",display:"flex",paddingLeft:"2%"}}>
                    <text className='text-des' style={{ fontSize:isMobile||isTablet?"1.5vw":"1.2vw",color:"white" }}>Profile</text>
                </div>
                <div style={{ width:"100%",display:"flex",justifyContent:"center",paddingBottom:"5%"}}>
                    <div style={{ display:"flex",flexDirection:"row",width:"95%" }}>
                        <div style={{ width:"40%"}} >
                            <Gap height={'2vh'}/>
                            <div>
                                <div style={{ fontSize:isMobile||isTablet?"1.3vw":"1vw",fontWeight:"bold" }}>E-Mail</div>
                                <div style={{  fontSize:isMobile||isTablet?"1.2vw":"0.8vw"}}>{dataUser?.user[0]?.username}</div>
                            </div>
                            <Gap height={'2vh'}/>
                            <div>
                                <div style={{ fontSize:isMobile||isTablet?"1.3vw":"1vw",fontWeight:"bold" }}>Telp</div>
                                <div style={{  fontSize:isMobile||isTablet?"1.2vw":"0.8vw"}}>{dataUser?.user[0]?.telp}</div>
                            </div>
                            <Gap height={'2vh'}/>
                            <div>
                                <div style={{ fontSize:isMobile||isTablet?"1.3vw":"1vw",fontWeight:"bold" }}>Name</div>
                                <div style={{  fontSize:isMobile||isTablet?"1.2vw":"0.8vw"}}>{dataUser?.user[0]?.name==null?"-":dataUser?.user[0]?.name}</div>
                            </div>
                            
                        </div>
                        <Gap width={'10vw'}/>
                        <div style={{ width:"30%"}} >
                            <Gap height={'2vh'}/>
                            <div>
                                <div style={{ fontSize:isMobile||isTablet?"1.3vw":"1vw",fontWeight:"bold" }}>Metatrader</div>
                                <div style={{  fontSize:isMobile||isTablet?"1.2vw":"0.8vw"}}>{dataUser?.user[0]?.metatrader_username}</div>
                            </div>
                            <Gap height={'2vh'}/>
                            <div>
                                <div style={{ fontSize:isMobile||isTablet?"1.3vw":"1vw",fontWeight:"bold" }}>Strategi</div>
                                <div style={{  fontSize:isMobile||isTablet?"1.2vw":"0.8vw",color:"rgba(39, 94, 148, 1)"}}>{dataUser?.strategy==null?"-":dataUser?.strategy}</div>
                            </div>
                            <Gap height={'2vh'}/>
                            <div>
                                <div style={{ fontSize:isMobile||isTablet?"1.3vw":"1vw",fontWeight:"bold" }}>Referral</div>
                                <div style={{  fontSize:isMobile||isTablet?"1.2vw":"0.8vw"}}>{dataUser?.user[0]?.referral_code==null?"-":dataUser?.user[0]?.referral_code}</div>
                            </div>
                        </div>
                        <Gap width={'10vw'}/>
                        <div style={{ width:"40%"}} >
                            <Gap height={'2vh'}/>
                            <div>
                                <div style={{ fontSize:isMobile||isTablet?"1.3vw":"1vw",fontWeight:"bold" }}>Profit Expectation</div>
                                <div style={{  fontSize:isMobile||isTablet?"1.2vw":"0.8vw"}}>{dataUser?.user[0]?.profit_expectation}</div>
                            </div>
                            <Gap height={'2vh'}/>
                            <div>
                                <div style={{ fontSize:isMobile||isTablet?"1.3vw":"1vw",fontWeight:"bold" }}>Risk Management</div>
                                <div style={{  fontSize:isMobile||isTablet?"1.2vw":"0.8vw"}}>{dataUser?.user[0]?.risk_management}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width:"100%",height:isMobile||isTablet?"11vh":"7vh",backgroundColor:"#ebebeb",borderTopLeftRadius:5,borderTopRightRadius:5,alignItems:"center",display:"flex",paddingLeft:"2%"}}>                    
                    <div onClick={()=>{setEdit(!edit)}}
                    style={{backgroundColor:'#6AC673',width:isMobile||isTablet?"20%":"10%",height:"2.5vw"}} className='button-profile'
                    >
                        <div style={{display:'flex'}}>
                        <text className='text-judul' style={{color:'white'}}>Edit</text>
                        </div>
                    </div>
                </div>
            </div>
            }
            {
                edit&&
                <div style={{borderRadius:5,boxShadow:"0 0 4px gray",marginLeft:isMobile||isTablet?"23%":"17.5%",width:"45vw",marginBottom:"5%"}}>
                <div style={{ width:"100%",height:isMobile||isTablet?"9vh":"6vh",backgroundColor:"#126bc4",borderTopLeftRadius:5,borderTopRightRadius:5,alignItems:"center",display:"flex",paddingLeft:"2%"}}>
                    <text className='text-des' style={{ fontSize:isMobile||isTablet?"1.5vw":"1.2vw",color:"white" }}>Profile</text>
                </div>
                <div style={{ width:"100%",display:"flex",justifyContent:"center",paddingBottom:"5%",flexDirection:"column",padding:"2%"}}>
                                <Gap height={15} />
                                <div className='verif-profil'>
                                    <div style={{width:dataUpdate.user==dataUser?.user[0]?.username?'100%':'80%'}}>
                                        <Input color={"#2A2626"} onChange={(val)=>{
                                            console.log(val)
                                            setDataUpdate((prev) => ({
                                                ...prev,
                                                user:val.target.value
                                            }))
                                        }} type="email" value={dataUpdate.user} placeholder="sample@gmail.com" label="E-mail"/>
                                    </div>
                                    {dataUpdate.user!=dataUser?.user[0]?.username&&
                                    <>
                                    <Gap width={'5%'} />
                                    <div style={{width:'45%'}}>
                                    <Input color={"#2A2626"} type="text" value={dataUpdate.verifEmail} onChange={(val)=>{
                                                setDataUpdate((prev) => ({
                                                    ...prev,
                                                    verifEmail:val.target.value
                                                }))
                                    }} placeholder="*******" label="Kode Verifikasi" />
                                    </div>
                                     <Gap width={'5%'} />
                                    <div style={{width:isTablet||isMobile?'30%':'20%'}}>
                                        <div onClick={()=>{verifEmail()}}
                                        className='button-profile' style={{ backgroundColor:"#6AC673" }}
                                        >
                                            <div style={{display:'flex'}}>
                                            <text className='text-judul' style={{color:'white'}}>VERIFIKASI</text>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    }
                                </div>
                                <Gap height={15} />
                                <div className='verif-profil'>
                                    <div style={{width:isTablet||isMobile?'45%':'75%'}}>
                                        <Input color={"#2A2626"} onChange={(val)=>{
                                            console.log(val)
                                            setDataUpdate((prev) => ({
                                                ...prev,
                                                metatraderUsername:val.target.value
                                            }))
                                        }} type="text" value={dataUpdate.metatraderUsername} placeholder="sample@gmail.com" label="Metatrader Username"/>
                                    </div>
                                    <Gap width={'5%'} />
                                    <div style={{width:isTablet||isMobile?'45%':'75%'}}>
                                        <Input color={"#2A2626"} onChange={(val)=>{
                                            console.log(val)
                                            setDataUpdate((prev) => ({
                                                ...prev,
                                                metatraderPassword:val.target.value
                                            }))
                                        }} type="password" value={dataUpdate.metatraderPassword} placeholder="*******" label="Metatrader Password"/>
                                    </div>
                                </div>
                                <Gap height={15} />
                                <div className='verif-profil' >
                                    <div style={{width:'23%'}}>
                                        <Input color={"#2A2626"} onChange={(val)=>{
                                            console.log(val)
                                            setDataUpdate((prev) => ({
                                                ...prev,
                                                profit_expectation:val.target.value
                                            }))
                                        }} type="number" value={dataUpdate.profit_expectation} placeholder="10%" label="Profit Expectation"/>
                                    </div>
                                    <Gap width={'2.5%'} />
                                    <div style={{width:'23%'}}>
                                        <Input color={"#2A2626"} onChange={(val)=>{
                                            console.log(val)
                                            setDataUpdate((prev) => ({
                                                ...prev,
                                                risk_management:val.target.value
                                            }))
                                        }} type="number" value={dataUpdate.risk_management} placeholder="10%" label="Risk Management"/>
                                    </div>
                                    <Gap width={'3%'} />
                                     <div style={{width:'49%'}}>
                                        <Input color={"#2A2626"} onChange={(val)=>{
                                            console.log(val)
                                            setDataUpdate((prev) => ({
                                                ...prev,
                                                name:val.target.value
                                            }))
                                        }} type="text" value={dataUpdate.name} placeholder="Adi" label="Name"/>
                                    </div>
                                </div>
                                
                                <Gap height={15} />
                                <div className='verif-profil'>
                                            <div style={{width:isMobile||isTablet?'30%':'10%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                <div className='phone-styles'>
                                                    <div>
                                                        <text class="text-judul" style={{color:'white'}}>+62</text>
                                                    </div>
                                                </div>
                                            </div>
                                            <Gap width={15} />
                                            <div style={{width:isTablet||isMobile?'75%':'36.5%'}}>
                                                <Input color={"#2A2626"} onChange={(val)=>{
                                                    console.log(val)
                                                            setDataUpdate((prev) => ({
                                                        ...prev,
                                                        telp:val.target.value
                                                    }))
                                                }} type="number" value={dataUpdate?.telp} placeholder="86748298329" label="Telp" disabled={verif}/>
                                                </div>
                                            <Gap width={'3%'} />
      
                                </div>
                </div>
                <div style={{ width:"100%",height:isMobile||isTablet?"11vh":"7vh",backgroundColor:"#ebebeb",borderTopLeftRadius:5,borderTopRightRadius:5,alignItems:"center",display:"flex",paddingLeft:"2%"}}>                    
                                    <div style={{width:'30%'}}>
                                        <div onClick={()=>{
                                                updateUser()
                                        }}
                                        style={{opacity:1,backgroundColor:'#6AC673',height:isMobile||isTablet?"4vw":"2.5vw"}} className='button-profile'
                                        >
                                            <div style={{display:'flex'}}>
                                            <text className='text-judul' style={{color:'white'}}>SIMPAN</text>
                                            </div>
                                        </div>
                                    </div>
                                    <Gap width={'5%'} />
                                    <div style={{width:'30%'}}>
                                        <div onClick={()=>{setEdit(!edit)}}
                                        style={{backgroundColor:'#F74949',height:isMobile||isTablet?"4vw":"2.5vw"}} className='button-profile'
                                        >
                                            <div style={{display:'flex'}}>
                                            <text className='text-judul' style={{color:'white'}}>CANCEL</text>
                                            </div>
                                        </div>
                                    </div>
                </div>
            </div>
            }
            <ToastContainer />
        </div>
    )
}
