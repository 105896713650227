import { url, server } from '../Constant.js';

async function FETCH_API(api, body,navigate=null, signal) {
    
    // body['userServer'] = server
    body['from_origin'] = "*";
    console.log("=================");
    let tokenData = localStorage.getItem("Token");
    console.log(url + api);
    console.log(body);
    console.log(tokenData);
    console.log("=================");
    
    try {      
        const response = await fetch(url + api, {
            signal: signal,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': tokenData,
                'Cache-Control': 'no-cache',
                'Accept-Encoding':'gzip;q=1.0, compress;q=0.5',
            },
            body: JSON.stringify(body)
        });

        if (response.status === 401) {
            // If 401 Unauthorized, redirect to the home page
            console.log("isinya woi",response)
            localStorage.setItem("Token","")
		    localStorage.setItem("User","")
            localStorage.setItem("dashboard","")
            if(navigate!=null){
                navigate("/")
            }
            return { status: "ERROR", message:response.message };
        }

        const data = await response.json();
        return data;

    } catch (error) {
        if (error.name === 'AbortError') {
            console.log('Request was cancelled');
            return { status: "ERROR", message: "Request was cancelled." };
        }

        console.log("FETCH API Error:", error);
        return { status: "ERROR", message: "An error occurred." };
    }
}

export default FETCH_API;
