
import '../Dashboard/userprofile.css';
import '../wallet/wallet.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logoCalendar from "../../assets/CalendarBlues.png"
import trade1 from '../../assets/screen.png'
import Empty from '../../component/empty/empty';
import Refresh from "../../assets/RefreshWhite.png"
import Navbar from '../../component/navbar/navbar';
import Gap from '../../component/Gap/Gap';
import { useNavigate } from "react-router-dom";
import React from 'react';
import profilewhite from '../../assets/userGray.png'
import FETCH_API from '../../config';
import 'react-toastify/dist/ReactToastify.css';
import formatterMoney from '../../component/formatMoney';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import RobotWhite from "../../assets/RobotGray.png"
import ReportWhite from "../../assets/Report2.png"
import WalletWhite from "../../assets/walletGray.png"
import { CalendarIcon} from '@heroicons/react/24/outline';
import LoadingSpinner from '../../component/loading/loading';
import SideBar from '../../component/sideBar/sideBar';

export default function Wallet({}){
    
    const navigate = useNavigate();
    const [pilihTab,setPilihTab]=React.useState(1)
    const [sidebar,setSidebar]=React.useState(false)
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 768 && window.innerWidth <= 1024);
    const [date,setDate]=React.useState(formatDateFirst(new Date()))
    const [date2,setDate2]=React.useState(new Date())
    const [dateLRB,setDateLRB]=React.useState(formatDateFirst(new Date()))
    const [date2LRB,setDate2LRB]=React.useState(formatDateLast(new Date()))
    const [dateLK,setDateLK]=React.useState(formatDateFirst(new Date()))
    const [date2LK,setDate2LK]=React.useState(new Date())
    const [dateWallet,setDateWallet]=React.useState(formatDateFirst(new Date()))
    const [date2Wallet,setDate2Wallet]=React.useState(formatDateLast(new Date()))
    const [dataUpdate,setDataUpdate]=React.useState({user:"",telp:"",verifEmail:"",verifTelp:""})
    const [dataUser,setDataUser]=React.useState({laba:0,komisi:0,user:[],strategy:''})
    const user = localStorage.getItem('User')
    const Id = localStorage.getItem('ID')
    const telp =localStorage.getItem('Telp')

    const [tampungindex, setTampungindex] = React.useState(0);
    const [pilihanItems, setPilihanItems] = React.useState(1);

    const handleCardClick = (items, clickedIndex) => {
        setTampungindex(clickedIndex);
        setPilihanItems(items)
    };
    const [password, setPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [newPassword2, setNewPassword2] = React.useState('');
    const [inputReferralCode, setInputReferralCode] = React.useState('');
    const [inputSaveReferralCode, setInputSaveReferralCode] = React.useState('');
    const [inputLinkPlatform, setInputLinkPlatform] = React.useState('');
    const [inputCommision,setInputCommision]=React.useState('')
    const [inputCommisionCompany,setInputCommisionCompany]=React.useState('')
    const [edit,setEdit]=React.useState(false)
    const [loading,setLoading]=React.useState(false)
    const [pageLaba,setPageLaba]=React.useState(0)
    const [halamanLaba,setHalamanLaba]=React.useState([])
    const [pageKomisi,setPageKomisi]=React.useState(0)
    const [halamanKomisi,setHalamanKomisi]=React.useState([])
    const [pageWallet,setPageWallet]=React.useState(0)
    const [halamanWallet,setHalamanWallet]=React.useState([])
    const [email, setEmail] = React.useState('');
    const [userName, setUserName] = React.useState('');
    const [data, setData] = React.useState('');
    function formatDate(params) {
        return moment(params).format('MMMM DD, YYYY')
    }
    function formatDatePayload(params) {
        return moment(params).format('YYYY-MM-DD')
    }
        function formatDateFirst(params) {
        return moment(params).format('YYYY-MM-01')
    }
            function formatDateLast(params) {
        return moment(params).format('2025-MM-DD')
    }
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };
    const handleInputReferralCode = (event) => {
        setInputReferralCode(event.target.value);
    };
    const handleInputSaveReferralCode = (event) => {
        setInputSaveReferralCode(event.target.value);
    };

    const handleInputLinkPlatform = (event) => {
        setInputLinkPlatform(event.target.value);
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handleUsernameChange = (event) => {
        setUserName(event.target.value);
    };
    const [tampungindex2, setTampungindex2] = React.useState(0);
    const [pilihanItems2, setPilihanItems2] = React.useState({});
    // console.log(pilihanItems)
    const handleCardClick2 = (items, clickedIndex) => {
        setTampungindex2(clickedIndex);
        setPilihanItems2(items)
    };

    const [trade,setTrade]=React.useState([])

    const [trade22,setDate22]=React.useState([])

    const [trade222,setTrade222]=React.useState()

    const tab = [
    {
        id: 1,
        name: 'Setting',
        img:RobotWhite,
        img2:RobotWhite
    },
    {
        id: 2,
        name: 'Laporan',
        img:ReportWhite,
        img2:ReportWhite
    },
    {
        id: 3,
        name: 'Wallet',
        img:WalletWhite,
        img2:WalletWhite
    }, 
        {
        id: 4,
        name: 'Profile',
         img:profilewhite,
        img2:profilewhite
    }, 
    //{
    //     id: 5,
    //     name: 'Change Password',
    //     img2:padlockwhite
    // }, 
    //     {
    //     id: 6,
    //     name: 'Logout',
    //     img2:LogoutWhite
    // }

]

        const [data2, setData2] = React.useState()


    React.useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width <= 768);
            setIsTablet(width > 768 && width <= 1024);
            console.log(isMobile, isTablet)
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    
    React.useEffect(() => {
        getuser()
        getuserStrategy()
        reportWallet()
    }, []);

    React.useEffect(() => {
        getParam()
    }, [window.location.href,date,date2,date2LK,date2LRB,date2Wallet,dateLK,dateLRB,dateWallet,pageLaba,pageKomisi,pageWallet]);

    async function getParam(){
		const getParameterByName = (name, url = window.location.href) => {
			name = name.replace(/[\[\]]/g, '\\$&');
			var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
				results = regex.exec(url);
			if (!results) return null;
			if (!results[2]) return '';
			return decodeURIComponent(results[2].replace(/\+/g, ' '));
		 }
		console.log(getParameterByName("id"))
        let id=getParameterByName("id")
        if(id=="Setting"){
            strategy()
            setPilihanItems(1)
        }
        if(id=="Laporan"){
            getLaporanLabaRugi()
            getLaporanKomisi()
            setPilihanItems(2)
        }
        if(id=="Wallet"){
            reportWallet()
            setPilihanItems(3)
        }
        if(id=="Profile"||id=="My Profile"){
            setPilihanItems(4)
        }
        if(id=="Change Password"){
            setPilihanItems(5)
        }
        if(id=="deposit"){
            setPilihanItems(7)
        }
                if(id=="withdraw"){
            setPilihanItems(8)
        }
    }


    async function changePassword() {
        console.log(newPassword)
        if(newPassword!=newPassword2){
            toast.error("Password Tidak Sama")
            return
        }
        if (!(/[A-Z]/.test(newPassword))) {
            toast.error("Password Harus Berisi Huruf Kapital")
            return
        }
        if (!(/[a-z]/.test(newPassword))) {
            toast.error("Password Harus Berisi Huruf Kecil")
            return
        }
        if (!(/\d/.test(newPassword))) {
            toast.error("Password Harus Berisi Angka")
            return
        }
        if (newPassword?.length<=6) {
            toast.error("Password Harus Lebih dari 6 Huruf")
            return
        }
        try {
            const changePassword = await FETCH_API("users/change/password", {
                "id": Id,
                "password": newPassword
            },navigate)
            console.log(changePassword)
            if (changePassword.status === 'OK') {
                toast.success(changePassword.message)
                setNewPassword('')
                setNewPassword2('')
            }
            else{
				if(changePassword.message!==undefined){
					toast.error(changePassword.message)
				}
				else{
					toast.error(changePassword.error.message)
				}
			}
        } catch (error) {
            console.log(error)
            toast.error(error)
        }
    }

    async function getuser() {
        try {
            const getuser = await FETCH_API("users/get1", {
                "id": Id,
            },navigate)
            console.log(getuser)
            if (getuser.status === 'OK') {
                // toast.success(getuser.message)
                // setData(getuser.data[0])
                setDataUser((prev) => ({
                    ...prev,
                    laba:getuser.labaRugi,
                    komisi:getuser.komisi,
                    user:getuser.user
                }))
            }
            else{
				if(getuser.message!==undefined){
					toast.error(getuser.message)
				}
				else{
					toast.error(getuser.error.message)
				}
			}
        } catch (error) {
            console.log(error)
            toast.error(error)
        }
    }

    async function getuserStrategy() {
        try {
            const getuser = await FETCH_API("strategy/get/user", {
                "userId": Id,
                // "date":formatDatePayload(new Date())
            },navigate)
            console.log(getuser)
            if (getuser.status === 'OK') {
                // toast.success(getuser.message)
                setData(getuser.data[0])
                setDataUser((prev) => ({
                    ...prev,
                    strategy:getuser.data.strategy_id,
                    data_strategy:{
                        komisi:getuser.data.commission,
                        deskripsi:getuser.data.description,
                        from:getuser.data.from,
                        to:getuser.data.to
                    }
                }))
            }
            else{
				if(getuser.message!==undefined){
					toast.error(getuser.message)
				}
				else{
					toast.error(getuser.error.message)
				}
			}
        } catch (error) {
            console.log(error)
            toast.error(error)
        }
    }


    async function strategy() {
        try {
            const strategy = await FETCH_API("strategy", {
                "userId": Id,
                "page": 0,
                "row": 3,
                "filter": {
                    "search": "",
                    "sort_col": "id",
                    "sort_by": "asc"
                }
            },navigate)
            console.log(strategy)
            if(strategy.status === 'OK'){
                // toast.success(strategy.message)
                setData2(strategy.data)
            }
            else{
                if(strategy.message!==undefined){
                toast.error(strategy.message)
                }
                else{
                toast.error(strategy.error.message)
                }
            }
            
        } catch (error) {
            toast.error(error)
        }

    }


    async function onChoose(item){
        try {
            const savestrategy = await FETCH_API("strategy/choose", {
                "userId":Id,
                "strategyId":item,
                // "from": formatDatePayload(date),
                // "to": formatDatePayload(date2)
            },navigate)
            console.log(savestrategy)
            if(savestrategy.status === 'OK'){
                toast.success(savestrategy.message)
                getuserStrategy()
                // navigate("/")
            }
            else{
                if(savestrategy.message!==undefined){
                toast.error(savestrategy.message)
                }
                else{
                toast.error(savestrategy.error.message)
                }
            }
        } catch (error) {
            toast.error(error)
        }

    }

    async function onCancelStrategy(){
        try {
            const savestrategy = await FETCH_API("strategy/cancel", {
                "userId":Id,
            },navigate)
            console.log(savestrategy)
            if(savestrategy.status === 'OK'){
                toast.success(savestrategy.message)
                getuserStrategy()
                // navigate("/")
            }
            else{
                if(savestrategy.message!==undefined){
                toast.error(savestrategy.message)
                }
                else{
                toast.error(savestrategy.error.message)
                }
            }
        } catch (error) {
            toast.error(error)
        }

    }

    async function getLaporanLabaRugi(ref=""){
        try {
            const laporan = await FETCH_API("report/lr", {
                "userId": Id,
                "page": pageLaba,
                "row": 50,
                "from": formatDatePayload(dateLRB),
                "to": formatDatePayload(date2LRB)
            },navigate)
            console.log(laporan)
            if(laporan.status === 'OK'){
                if(ref=="ref"){
                    if(laporan.data.length>0){
                        toast.success(laporan.message)
                    }
                    else{
                        toast.info(`Data dari Tanggal ${formatDatePayload(dateWallet)} sampai ${formatDatePayload(date2Wallet)} Kosong`)
                    }
                }
                // toast.success(laporan.message)
                setTrade(laporan.data)
                let total=laporan.total
                let jumlah=total/50
                let halaman= Math.ceil(jumlah)
                let datanya=[]
                for(var a=0;a<halaman;a++){
                    datanya.push(a+1)
                }
                console.log("datanya",datanya)
                setHalamanLaba(datanya)
                // navigate("/")
            }
            else{
                if(laporan.message!==undefined){
                toast.error(laporan.message)
                }
                else{
                toast.error(laporan.error.message)
                }
            }
        } catch (error) {
            toast.error(error)
        }
    }
    
    async function getLaporanKomisi(ref=""){
        try {
            const laporan = await FETCH_API("report/komisi", {
                "userId": Id,
                "page": pageKomisi,
                "row": 50,
                "from": formatDatePayload(dateLK),
                "to": formatDatePayload(date2LK)
            },navigate)
            console.log(laporan)
            if(laporan.status === 'OK'){
                // toast.success(laporan.message)
                if(ref=="ref"){
                    if(laporan.data.length>0){
                        toast.success("Berhasil Ambil data Report Komisi")
                    }
                    else{
                        toast.info(`Data dari Tanggal ${formatDatePayload(dateWallet)} sampai ${formatDatePayload(date2Wallet)} Kosong`)
                    }
                }
                setDate22(laporan.data)
                let total=laporan.total
                let jumlah=total/50
                let halaman= Math.ceil(jumlah)
                let datanya=[]
                for(var a=0;a<halaman;a++){
                    datanya.push(a+1)
                }
                console.log("datanya",datanya)
                setHalamanKomisi(datanya)
                // navigate("/")
            }
            else{
                if(laporan.message!==undefined){
                toast.error(laporan.message)
                }
                else{
                toast.error(laporan.error.message)
                }
            }
        } catch (error) {
            toast.error(error)
        }
    }

    async function updateUser(){
        if(dataUpdate.user!=dataUser?.user[0]?.username){
            try {
                const laporan = await FETCH_API("users/update", {
                    "id": Id,
                    "username": dataUpdate.user,
                    "telp": dataUpdate.telp,
                    "codeEmailVerification": dataUpdate.verifEmail,
                },navigate)
                console.log(laporan)
                if(laporan.status === 'OK'){
                    toast.success("Berhasil update data anda, anda akan diarahkan ke halaman login untuk login kembali dalam 10 detik")
                   
                }
                else{
                    if(laporan.message!==undefined){
                    toast.error(laporan.message)
                    }
                    else{
                    toast.error(laporan.error.message)
                    }
                }
            } catch (error) {
                toast.error(error)
            }
        }
        else{
             toast.error("email tidak boleh sama")
        }
    }

    async function verifEmail(){
        if(dataUpdate.user!=dataUser?.user[0]?.username){
            try {
                const laporan = await FETCH_API("users/save/verification", {
                    "username": dataUpdate.user,
                    "subject": "Email Verification"
                },navigate)
                console.log(laporan)
                if(laporan.status === 'OK'){
                    toast.success(laporan.message)
                    // navigate("/")
                }
                else{
                    if(laporan.message!==undefined){
                    toast.error(laporan.message)
                    }
                    else{
                    toast.error(laporan.error.message)
                    }
                }
            } catch (error) {
                toast.error(error)
            }
        }
        else{
             toast.error("email tidak boleh sama")
        }
    }

    async function reportWallet(ref=""){
         try {
            setLoading(true)
            const laporan = await FETCH_API("report/wallet", {
                "userId": Id,
                "page": pageWallet,
                "row": 50,
                "from": formatDatePayload(dateWallet),
                "to": formatDatePayload(date2Wallet)
            },navigate)
            console.log(laporan)
            if(laporan.status === 'OK'){
                if(ref=="ref"){
                    if(laporan.data.length>0){
                        toast.success(laporan.message)
                    }
                    else{
                        toast.info(`Data dari Tanggal ${formatDatePayload(dateWallet)} sampai ${formatDatePayload(date2Wallet)} Kosong`)
                    }
                }
                // toast.success(laporan.message)
                setTrade222(laporan.data)
                let total=laporan.total
                let jumlah=total/50
                let halaman= Math.ceil(jumlah)
                let datanya=[]
                for(var a=0;a<halaman;a++){
                    datanya.push(a+1)
                }
                console.log("datanya",datanya)
                setHalamanWallet(datanya)
    
                setLoading(false);

                // navigate("/")
            }
            else{
                if(laporan.message!==undefined){
                toast.error(laporan.message)
                }
                else{
                toast.error(laporan.error.message)
                }
                setLoading(false);
            }
        } catch (error) {
            toast.error(error)
                setLoading(false);
        }
    }
        const [pilihID,setPilihID]=React.useState("")

        const buttonRefWallet = React.useRef(null);

        const handleClick = () => {
            if (buttonRefWallet.current) {
                // buttonRef.current.classList.remove('animate-click');
                void buttonRefWallet.current.offsetWidth; // Trigger reflow to reset animation
                // buttonRef.current.classList.add('animate-click');
                buttonRefWallet.current.classList.add('color-change-wallet');
                setTimeout(() => {
                    buttonRefWallet.current.classList.remove('color-change-wallet');
                }, 300); // Adjust this timeout to match your animation duration
            }
        };

    async function cancelWallet(id){
            try {
            const laporan = await FETCH_API("wallet/cancel", {
                "id":id,
                "userId": Id
            },navigate)
            console.log(laporan)
            if(laporan.status === 'OK'){
                toast.success(laporan.message)
                reportWallet()
                // navigate("/")
            }
            else{
                if(laporan.message!==undefined){
                toast.error(laporan.message)
                }
                else{
                toast.error(laporan.error.message)
                }
            }
        } catch (error) {
            toast.error(error)
        }
    }


    return(
        <div style={{ width:"100vw",minHeight:'100vh' }}>
            {loading==true&&
                <LoadingSpinner/>
            }
            <SideBar isMobile={isMobile} isTablet={isTablet}/>
            <Navbar/>
            <Gap height={70}></Gap>
            {/* <div style={{  marginLeft:"15%",marginBottom:"1%"}}>
                <text className='text-des' style={{ fontSize:"1.2vw",color:"black" }}>USER WALLET</text>
            </div> */}
            <div style={{width:'35vw',display:'flex',justifyContent:'center',marginLeft:isMobile||isTablet?"23vw":"15.5vw"}}>
                <div style={{boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.1)',width:isMobile||isTablet?'100%':'90%',height:isMobile||isTablet?"16vh":'13vh',borderRadius:10,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:"#126bc4"}}>
                    <div style={{width:'50%',height:'20vh'}}>
                        <div style={{width:'100%',height:'12vh',alignItems:'flex-end',display:'flex',paddingLeft:'5%'}}>
                            <text className='text-judul' style={{fontSize:isMobile||isTablet?'22px':'4vh'}}>Rp {formatterMoney(dataUser?.user[0]?.wallet)}</text>
                        </div>
                        <div style={{width:'100%',height:'8vh',alignItems:'flex-start',display:'flex',paddingLeft:'5%'}}>
                            <text className='text-judul' style={{fontSize:isMobile||isTablet?'12px':'2vh'}}>Current Wallet Balance</text>
                        </div>
                    </div>
                    <div style={{width:'50%',height:'20vh',display:'flex',justifyContent:'flex-end',alignItems:'center',paddingRight:'5%'}}>
                        <div onClick={()=>{        
                            navigate(`/deposit`)
        }}
                        style={{backgroundColor:'#6AC673',borderRadius:10,width:isMobile||isTablet?'120px':'18vh',height:'48px',justifyContent:'center',alignItems:'center',display:'flex',cursor:'pointer'}}
                        >
                            <div style={{display:'flex'}}>
                            <text className='text-judul' style={{color:'white',fontSize:isMobile||isTablet?'12px':'2vh'}}>DEPOSIT</text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Gap height={"5vh"}/>
            <div style={{width:isMobile||isTablet?'73.5vw':'83vw',display:'flex',justifyContent:'center',marginLeft:isMobile||isTablet?"23vw":"17vw",boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.1)',backgroundColor:"white",flexDirection:"column"}}>
                <div style={{width:'100%',display:'flex',justifyContent:'flex-start',alignItems:'center',height:isMobile||isTablet?"9vh":'6vh',backgroundColor:"#126bc4",borderTopLeftRadius:5,borderTopRightRadius:5}}>
                        <text className='text-des' style={{color:'white',fontSize:isMobile||isTablet?"1.8vw":"1.2vw",paddingLeft:'2%'}}>All Transaction</text>
                </div>
                <Gap height={"2vh"}/>
                <div style={{width:'100%',height:isMobile||isTablet?"9vh":'6vh',display:'flex',justifyContent:'flex-start',alignItems:'center',paddingLeft:isMobile||isTablet?'2%':'2%'}}>
                    <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
                        {/* <text className='text-judul' style={{color:'gray',paddingRight:'5%'}}>From</text> */}
                        <DatePicker 
                            onChange={(v)=>{setDateWallet(formatDate(v))}}
                            customInput={
                            <div
                            style={{backgroundColor:'rgb(238, 238, 238)',boxShadow:'0 0 1px black',borderRadius:5,width:isMobile||isTablet?'180px':'180px',height:'48px',justifyContent:'center',alignItems:'center',display:'flex',cursor:'pointer'}}
                            >
                                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <CalendarIcon  style={{width:'2vh',height:'2vh'}}></CalendarIcon>
                                <Gap width={'1vh'}></Gap>
                                <text className='text-des' style={{color:'rgb(48, 48, 48)',fontSize:isMobile||isTablet?'1.2vw':"0.9vw"}}>{formatDate(dateWallet)}</text>
                                </div>
                            </div>}
                        />
                    </div>
                    <Gap width={isMobile||isTablet?'10px':'2px'}></Gap>
                    <div style={{width:'5vh',height:'5vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <text className='text-des' style={{color:'rgb(48, 48, 48)',paddingRight:'5%' ,fontSize:isMobile||isTablet?'1.2vw':"0.9vw"}}>To</text>
                    </div>
                    <Gap width={isMobile||isTablet?'10px':'2px'}></Gap>
                    <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
                        {/* <text className='text-judul' style={{color:'gray',paddingRight:'5%'}}>To</text> */}
                        <DatePicker 
                            onChange={(v)=>{setDate2Wallet(formatDate(v))}}
                        customInput={
                            <div
                            style={{backgroundColor:'rgb(238, 238, 238)',boxShadow:'0 0 1px black',borderRadius:5,width:isMobile||isTablet?'180px':'180px',height:'48px',justifyContent:'center',alignItems:'center',display:'flex',cursor:'pointer'}}
                            >
                                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <CalendarIcon  style={{width:'2vh',height:'2vh'}}></CalendarIcon>
                                <Gap width={'1vh'}></Gap>
                                <text className='text-des' style={{color:'rgb(48, 48, 48)',fontSize:isMobile||isTablet?'1.2vw':"0.9vw"}}>{formatDate(date2Wallet)}</text>
                                </div>
                            </div>}
                        />
                    </div>
                    <Gap width={isMobile||isTablet?'10px':'10px'}></Gap>
                    {
                        (!isMobile&&!isTablet)&&
                        <div onClick={()=>{
                            handleClick()
                            reportWallet('ref')
                                    }} style={{width:isMobile||isTablet?'30%':'5%',marginTop:isMobile||isTablet?'5%':0,marginBottom:isMobile||isTablet?'5%':0}}  className='robot-center'>
                                        <div ref={buttonRefWallet}
                                        style={{ 
                                            backgroundColor: '#343a3d',
                                            borderRadius: '10px',
                                            width: '45px',
                                            height: '46px',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                                        }}
                                        >
                                            <div style={{display:'flex'}}>
                                                <img src={Refresh} style={{ width:'20px',height:'20px' }}></img>
                                                {/* <Gap width={"10px"}/>
                                            <text className='text-judul' style={{color:'white'}}>Refresh</text> */}
                                            </div>
                                        </div>
                        </div>
                    }
                                    {(isMobile||isTablet)&&
                    <div onClick={()=>{
                        handleClick()
                                }} style={{width:isMobile||isTablet?'30%':'5%',marginTop:isMobile||isTablet?'5%':0,marginBottom:isMobile||isTablet?'5%':0}}  className='robot-center'>
                                    <div ref={buttonRefWallet}
                                    style={{ 
                                        backgroundColor: 'rgb(48, 48, 48)',
                                        borderRadius: '10px',
                                        width: '45px',
                                        height: '46px',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                                    }}
                                    >
                                        <div style={{display:'flex'}}>
                                            <img src={Refresh} style={{ width:'20px',height:'20px' }}></img>
                                            {/* <Gap width={"10px"}/>
                                        <text className='text-judul' style={{color:'white'}}>Refresh</text> */}
                                        </div>
                                    </div>
                    </div>
                }
                </div>
                {
                trade222?.length!==0?
                         <div style={{ padding:"2%",width:"100%",display:"flex",flexDirection:"column" }}>
                            <div style={{ boxShadow:"0 0 1.5px 0 gray",borderRadius:5 }}>
                                <div style={{ display:"flex",width:"100%",backgroundColor:"#ebebeb",borderTopLeftRadius:5,borderTopRightRadius:5}}>
                                    <div className='text-judul' style={{ width:"25%",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw"}}>Tanggal</div>
                                    <div className='text-judul' style={{ width:"25%",padding:"1%" ,fontSize:isMobile||isTablet?'1.2vw':"1vw"}}>Status</div>
                                    <div className='text-judul' style={{ width:"20%",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw" }}>Tipe</div>
                                    <div className='text-judul' style={{ width:"15%",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw" }}>Nominal</div>
                                    <div className='text-judul' style={{ width:"15%",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw" }}>Action</div>
                                  
                                
                                </div>
                                                                {
                                    trade222?.map((items,index)=>{
                                    let backgroundColor = index % 2 === 0 ?  'rgb(248, 248, 248)':'#ebebeb';
                                    return(
                                   <div style={{ display:"flex",width:"100%",backgroundColor:backgroundColor,borderBottomRightRadius:5,borderBottomLeftRadius:5}}>
                                        <div style={{ width:"25%",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw" }}>{formatDate(items.date)}</div>
                                        <div style={{ width:"25%",padding:"1%",color:items.status=="paid"?"6AC673":"",fontSize:isMobile||isTablet?'1.2vw':"1vw" }}>{items.status}</div>
                                         <div style={{ width:"20%",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw" }}>{items.type}</div>
                                        <div style={{ width:"15%",padding:"1%",textAlign:"end" ,fontSize:isMobile||isTablet?'1.2vw':"1vw"}}>{formatterMoney(items.amount)}</div>
                                        {
                                                items.status=="pending"?
                                         <div onClick={()=>{cancelWallet(items.id)}}  style={{ width:"15%",padding:"1%",color:"red",cursor:"pointer",fontSize:isMobile||isTablet?'1.2vw':"1vw" }}>Cancel</div>
                                         :
                                         <div style={{ width:"15%" }}></div>
                                        }
                                
                                    </div>
                                    )
                                    })
                                }
                            </div>
                        </div> 
                    
                    
                    :
                    <Empty data={"Laporan"}></Empty>
                }
            </div>
            <ToastContainer />

        </div>
    )
}