
import '../Dashboard/userprofile.css';
import '../withdraw/withdraw.css'
import Gap from '../../component/Gap/Gap';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import pc from '../../assets/pc.png'
import DatePicker from "react-datepicker";
import Input from '../../component/Input/input';
import qrbg from "../../assets/qris204.png"
import qr from "../../assets/qr.jpg"
import FETCH_API from '../../config';
import { useNavigate } from "react-router-dom";
import DropDown from "../../assets/Dropdown.png"
import Navbar from '../../component/navbar/navbar';
import LoadingSpinner from '../../component/loading/loading';
import SideBar from '../../component/sideBar/sideBar';

export default function Withdraw({}){
    const Id = localStorage.getItem('ID')
    const [amount,setAmount]=React.useState(0)
    const [rekening,setRekening]=React.useState("")
    const [bank,setBank]=React.useState("")
    const [nama,setNama]=React.useState("")
    const [loading,setLoading]=React.useState(false)
        const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 768 && window.innerWidth <= 1024);
    const navigate = useNavigate();

    async function Withdraws(){
            try {
                setLoading(true)
            const laporan = await FETCH_API("wallet/withdraw", {
                "userId": Id,
                "amount":amount,
                "rekening":rekening,
                "bank": bank,
                "atasNama":nama

            },navigate)
            console.log(laporan)
            if(laporan.status === 'OK'){
                setLoading(false)
                toast.success(laporan.message)
                navigate("/wallet")
            }
            else{
                if(laporan.message!==undefined){
                toast.error(laporan.message)
                }
                else{
                toast.error(laporan.error.message)
                }
                setLoading(false)
            }
        } catch (error) {
            toast.error(error)
            setLoading(false)
        }

    }
    return(
        <div>
            {loading==true&&
                <LoadingSpinner/>
            }
                        <SideBar isMobile={isMobile} isTablet={isTablet}/>
            <Navbar/>
            <Gap height={100}></Gap>
            <div className='main-withdraw' style={{ marginLeft:"5%",padding:"3%",width:"90vw",backgroundColor:"white",borderRadius:5,marginBottom:"10%"}}>
                <div style={{width:'100%'}} className='center-withdraw'>
                    <text className="text-judul" style={{color:'#2A2626',fontSize:'23px'}}>WITHDRAW</text>
                </div>
                <div style={{width:'100%',flexDirection:"row"}} className='center-withdraw'>
                        <div style={{width:'50%'}} className='main-content-withdraw'>
                            <Gap height={'2vh'}/>
                            <div style={{width:'100%'}}>
                            <Input color={"#2A2626"} type="text" onChange={(val)=>{
                            }} placeholder="*******" label="Username" value={Id} disabled/>
                            </div>
                            <Gap height={'2vh'}/>
                            <div style={{width:'100%'}}>
                            <Input color={"#2A2626"} type="number" onChange={(val)=>{
                                setAmount(val.target.value)
                            }} placeholder="*******" label="Nominal" value={amount}/>
                            </div>
                            <Gap height={'2vh'}/>
                            <div style={{width:'100%',height:'auto',display:'flex'}}>
                                <div class="dropdown" style={{ paddingTop:'1.5%' }}>
                                <button  class="dropbtn button-drop-wd">
                                    <text className='text-des' style={{color:'gray'}}>   {bank==""?"Pilih":bank}   </text>
                                    <img style={{paddingLeft:'4%',width:'20px',width:'20px'}} src={DropDown}></img>
                                </button>
                                <div class="dropdown-content">
                                    <a className='text-des' style={{cursor:'pointer'}} onClick={()=>{setBank("BCA")}} >BCA</a>
                                    <a className='text-des' style={{cursor:'pointer'}} onClick={()=>{setBank("BRI")}} >BRI</a>
                                </div>
                                </div>
                                <Gap width={'2vh'} />
                                <div style={{width:'100%',paddingTop:'1.5%'}}>
                                    <Input color={"#2A2626"} type="number" onChange={(val)=>{
                                        setRekening(val.target.value)
                                    }} placeholder="*******" label="Nomor Rekening" value={rekening}/>
                                    </div>
                            </div>
                            <Gap height={'2vh'}/>
                            <div style={{width:'100%'}}>
                            <Input color={"#2A2626"} type="text" onChange={(val)=>{
                                setNama(val.target.value)
                            }} placeholder="*******" label="Atas Nama" value={nama}/>
                            </div>
                            <Gap height={'2vh'}/>
                            <div style={{width:'100%'}} className='button-cover-wd'>
                                <div style={{width:'50%'}}>
                                    <div onClick={()=>{Withdraws()}}
                                    style={{backgroundColor:'#6AC673'}} className='center-withdraw button-css-wd' 
                                    >
                                        <div style={{display:'flex'}}>
                                        {/* <img src={Save} style={{width:'2.5vh',height:'2.5vh'}}></img> */}
                                        {/* <Gap width={'2vh'}></Gap> */}
                                        <text className='text-judul' style={{color:'white'}}>PROSES</text>
                                        </div>
                                    </div>
                                </div>
                                <Gap width={'5%'} />
                                <div style={{width:'50%'}}>
                                    <div onClick={()=>{
                                        navigate(`/wallet`)
                                        }}
                                    style={{backgroundColor:'#F74949'}} className='center-withdraw button-css-wd'
                                    >
                                        <div style={{display:'flex'}}>
                                        {/* <img src={Save} style={{width:'2.5vh',height:'2.5vh'}}></img> */}
                                        {/* <Gap width={'2vh'}></Gap> */}
                                        <text className='text-judul' style={{color:'white'}}>CANCEL</text>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 

                </div>
            </div>
            <ToastContainer />
        </div>
    )
}