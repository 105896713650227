import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import "./card.css"

function Cards({title,deskripsi,img,isMobile=false,isTablet=false,button1='',button2='',button3=''}) {
  return (
      <div className='card-main-dev1' style={{width:isMobile||isTablet?'70%':'30%'}}>
          <div style={{ width: '100%', height: '35%' ,display:'flex',justifyContent:'center',alignItems:'center'}}>
              <img src={img} style={{width:isMobile||isTablet?'20vw':'10vw',height:isMobile||isTablet?'10vh':'20vh'}}></img>
          </div>
          <div style={{ width: '100%', height: '40%' ,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',padding:'0px 20px 0px 20px'}}>
              <text className='text-judul' style={{ fontSize: isMobile || isTablet ? '5vw' : '4vh',  color: 'black' }}>{title}</text>
              <text className='text-des' style={{ fontSize: isMobile || isTablet ? '2.5vw' : '2vh', color: 'black' }}>{deskripsi}</text>
          </div>
          <div style={{ width: '100%', height: '25%' ,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',padding:'0px 20px 0px 20px'}}>
              <text className='text-judul' style={{fontSize: isMobile || isTablet ? '3vw' : '2vh', color: '#3582B8' }}>{button1}</text>
              <div style={{height:'2vh'}}></div>
              <text className='text-judul' style={{fontSize: isMobile || isTablet ? '3vw' : '2vh', color: '#3582B8' }}>{button2}</text>
              <div style={{height:'2vh'}}></div>
              <text className='text-judul' style={{fontSize: isMobile || isTablet ? '3vw' : '2vh', color: '#3582B8' }}>{button3}</text>
          </div>
      </div>
  );
}

export default Cards;