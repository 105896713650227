// LoadingSpinner.js
import React from 'react';
import './loading.css'; // Import custom styles if needed

const LoadingSpinner = () => {
  return (
    <div className="loading-container">
      <div className="spinner"></div>
      <p>Loading...</p>
    </div>
  );
};

export default LoadingSpinner;