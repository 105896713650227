import React, { Component } from 'react'
import { HashRouter as Router, Routes, Route,Redirect } from "react-router-dom";
import Home from '../pages/Home/home'
import Login from "../pages/Login/login";
import Regis from "../pages/Regis/regis"
import 'bootstrap/dist/css/bootstrap.min.css';
import Rules from "../pages/Rules/rules";
import ForgetPassword from '../pages/ForgetPassword/forget'
import About from "../pages/About/about"
import Profile from "../pages/profile/profile";
import Wallet from '../pages/wallet/wallet';
import Deposit from '../pages/deposit/deposit';
import Withdraw from '../pages/withdraw/withdraw';
import AdminSetting from '../pages/AdminSetting/adminSetting';
import AdminWithdraw from "../pages/AdminWithdraw/adminWithdraw"
import CopyTrading from "../pages/CopyTrading/copytrading"

function Apps() {

    React.useEffect(() => {
        if (window.location.hostname.startsWith("www")) {
            window.location.href = window.location.href.replace("www.", "");
        }
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, "", window.location.href);
        };
    },[])
    return (

      <Router>
        <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/regis' element={<Regis />} />
                <Route path='/resetPassword' element={<ForgetPassword />}/>
                {/* <Route path='/dashboard' element={<UserProfile />}/> */}
                <Route path='/home' element={<Home />}/>
                <Route path='/'  element={<Home />}/>
                <Route path='/About'  element={<About />}/>
                <Route path='/rules'  element={<Rules />}/>
                <Route path='/profile'  element={<Profile />}/>
                <Route path='/wallet'  element={<Wallet />}/>
                <Route path='/deposit'  element={<Deposit />}/>
                <Route path='/withdraw'  element={<Withdraw />}/>
                <Route path='/usersetting' element={<AdminSetting />}/>
                <Route path='/userwithdraw' element={<AdminWithdraw />}/>
                <Route path='/copytrading' element={<CopyTrading />}/>
                {/* <Redirect from="/"  to="/Profil"/> */}
            </Routes>
            </Router>
    )
}

export default Apps