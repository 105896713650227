import baiLogo from "./bai-logo.png";
import gmail from "./gmail.png";
import icon from "./icon.png";
import inves from "./inves.png";
import keunggulan1 from "./keunggulan1.png";
import keunggulan2 from "./keunggulan2.png";
import keunggulan3 from "./keunggulan3.png";
import visi from "./visi.png";
import wa from "./wa.png";
import easy from "./easy.png";
import publics from "./public.png";
import customer from "./customer.png";
import skull from "./skull.png"
import partner from "./partners.png"

const images = {
  baiLogo,
  inves,
  keunggulan1,
  keunggulan2,
  keunggulan3,
  visi,
  wa,
  gmail,
  icon,
  easy,
  publics,
  customer,
  skull,
  partner
};

export default images;
