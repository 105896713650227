import React from 'react'
import { useRef } from 'react';
import UruzB from '../../assets/UruzBlue.png'
import UruzA from '../../assets/UruzWhite.png'
import Gap from '../../component/Gap/Gap';
import Button from '../../component/button/button';
import Input from '../../component/Input/input';
import FETCH_API from '../../config';
import {useLocation,useNavigate} from 'react-router-dom';
import Button2 from '../../component/button/button2';
import image from '../../assets/image6.png'
import "./regis.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OtpInput from 'react-otp-input';
import firebase from '../../firebase'
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, onAuthStateChanged} from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc} from "firebase/firestore";
import Verif from "../../assets/Verif.png"
import { baseURL } from '../../Constant';
import Cards from '../../component/card/cardStrategy';
import pc from '../../assets/pc.png'
import DropDown from "../../assets/Dropdown.png"
import LoadingSpinner from '../../component/loading/loading';
import { ChevronDoubleRightIcon} from '@heroicons/react/24/solid';

function Home() {
	const navigate=useNavigate()
	const [loading,setLoading]=React.useState(false)
	const windowSize = useRef([window.innerWidth, window.innerHeight]);
	const [data,setData]=React.useState({name:'',pass:'',pass2:"",ref:'',email:'',code:"",telp:"",otp:"",stategyId:"",duration:0,profit_expectation:0,risk_management:0})
	const location = useLocation();
	const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 768 && window.innerWidth <= 1024);
	const [forget,setForget]=React.useState(false)
	const [verif,setVerif]=React.useState(true)
	const [code,setCode]=React.useState(false)
	const [otp,setOtp]=React.useState("")
	const [timeOut, setTimeOut] = React.useState(0)
	const [loadingOtp, setLoadingOTP] = React.useState(false)
	const [captchaCalled, setCaptchaCalled] = React.useState(false)
	const [recaptcha, setRecaptcha] = React.useState()
	const [ceklis,setCeklis]=React.useState(false)
	const buttonRef = React.useRef(null);
	const buttonRef2 = React.useRef(null);
	const buttonRef3 = React.useRef(null);
	const [tampungindex, setTampungindex] = React.useState(0);
	const [pilihDurasi,setPilihDurasi]=React.useState("Durasi")
	const [pilihanItems,setPilihanItems]=React.useState();
	const handleCardClick = (items, clickedIndex) => {
        setTampungindex(clickedIndex);
        setPilihanItems(items)
		setData((prev) => ({
			...prev,
			stategyId:items?.id
		}))
    };
	const [dataChoose, setDataChoose] = React.useState([

    ],)

    const handleClick = () => {
		if (buttonRef.current) {
            // buttonRef.current.classList.remove('animate-click');
            void buttonRef.current.offsetWidth; // Trigger reflow to reset animation
            // buttonRef.current.classList.add('animate-click');
            buttonRef.current.classList.add('color-changes');
            setTimeout(() => {
                buttonRef.current.classList.remove('color-changes');
            }, 300); // Adjust this timeout to match your animation duration
        }
    };
	const handleClick2 = () => {
		if (buttonRef2.current) {
            // buttonRef.current.classList.remove('animate-click');
            void buttonRef2.current.offsetWidth; // Trigger reflow to reset animation
            // buttonRef.current.classList.add('animate-click');
            buttonRef2.current.classList.add('color-changes');
            setTimeout(() => {
                buttonRef2.current.classList.remove('color-changes');
            }, 300); // Adjust this timeout to match your animation duration
        }
    };
	const handleClick3 = () => {
		if (buttonRef3.current) {
            // buttonRef.current.classList.remove('animate-click');
            void buttonRef3.current.offsetWidth; // Trigger reflow to reset animation
            // buttonRef.current.classList.add('animate-click');
            buttonRef3.current.classList.add('color-changes-regis');
            setTimeout(() => {
                buttonRef3.current.classList.remove('color-changes-regis');
            }, 300); // Adjust this timeout to match your animation duration
        }
    };

	async function getParam(){
		const getParameterByName = (name, url = window.location.href) => {
			name = name.replace(/[\[\]]/g, '\\$&');
			var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
				results = regex.exec(url);
			if (!results) return null;
			if (!results[2]) return '';
			return decodeURIComponent(results[2].replace(/\+/g, ' '));
		 }
		console.log("test",getParameterByName("param"))
        let refer=getParameterByName("param")
				if(refer!=null&&refer!=undefined&&refer!=""){
					setData((prev) => ({
						...prev,
						ref:refer
					}))
				}
	}

	React.useEffect(() => {
		getParam()
		getStrategy()
        const handleResize = () => {
            const width = window.innerWidth;
            setIsMobile(width <= 768);
            setIsTablet(width > 768 && width <= 1024);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowSize]);

	
	async function daftarAkun(){
		console.log(data.pass)
		if(data.pass!=data.pass2){
				toast.error("Password Tidak Sama")
				return
		}
		if (!(/[A-Z]/.test(data.pass))) {
				toast.error("Password Harus Berisi Huruf Kapital")
				return
		}
		if (!(/[a-z]/.test(data.pass))) {
				toast.error("Password Harus Berisi Huruf Kecil")
				return
		}
		if (!(/\d/.test(data.pass))) {
				toast.error("Password Harus Berisi Angka")
				return
		}
		if (data.pass?.length<=6) {
				toast.error("Password Harus Lebih dari 6 Huruf")
				return
		}
		if (data.stategyId?.length<=1) {
				toast.error("Pilih Strategi Terlebih dahulu")
				return
		}
		// if (data.duration<1) {
		// 		toast.error("Pilih Durasi Terlebih dahulu")
		// 		return
		// }
		let dataInput={
			// "email": data.email,
			// "username": data.name,
			// "password": data.pass,
			"username": data.email,
			"codeEmailVerification":data.code,
			"telp":"+62"+data.telp.replace(/ /g,''),
			"password":data.pass,
			"successRedirectUrl": baseURL,
	 		"strategyId": data.stategyId,
			"role":"user",
			"name":data.name,
			"profit_expectation":data.profit_expectation,
			"risk_management":data.risk_management,
			"strategyDuration":360
		}
		if(data?.ref?.length>0){
			dataInput["referralCode"]=data.ref
		}
		console.log(dataInput)
		if(dataInput.username?.length>0&&dataInput.telp?.length>0&&dataInput.password?.length>0){
			try {
				setLoading(true)
				const daftar = await FETCH_API("users/register", dataInput)
				if(daftar.status=="OK"){
					toast.success(daftar)
					console.log(daftar)
					if(daftar.data!==null&&daftar.data!==undefined){
						window.location.href = daftar.data.invoice_url
					}
					else{
						navigate("/Login");
					}
					// navigate("/Login");
					// window.location.href = daftar.data.invoice_url
				}
				else{
					if(daftar.message!==undefined){
						toast.error(daftar.message)
					}
					else{
						toast.error(daftar.error.message)
					}
				}
				setLoading(false)
			} catch (error) {
				toast.error(error)
				setLoading(false)
			}
		}
	
	}

	async function verifikasi(){
		try {
			setLoading(true)
			const daftar = await FETCH_API("users/save/verification", 
				{
					"username": data.email,
					"subject": "Email Verification"
				}
			)
			if(daftar.status=="OK"){
				toast.success("Email Terkirim")
				setCode(true)
			}
			else{
				if(daftar.message!==undefined){
					toast.error(daftar.message)
				}
				else{
					toast.error(daftar.error.message)
				}
			}
			setLoading(false)
		} catch (error) {
			toast.error(error)
			setLoading(false)
		}
	}



	async function getStrategy() {
		try {
			setLoading(true)
			const data = await FETCH_API("strategy/get-strategies", 
				{
					"page": 0,
					"row": 20,
					"filter": {
						"search": "",
						"sort_col": "id",
						"sort_by": "asc"
					}
				}
			)
			if(data.status=="OK"){
				console.log(data,"data")
				setDataChoose(data?.data)
				setData((prev) => ({
					...prev,
					stategyId:data.data[0]?.id
				}))
			}
			else{
				if(data.message!==undefined){
					toast.error(data.message)
				}
				else{
					toast.error(data.error.message)
				}
			}
			setLoading(false)
		} catch (error) {
			toast.error(error)
			setLoading(false)
		}
	}

	async function simpanDurasi(text,jumlah) {
		setPilihDurasi(text)
		setData((prev) => ({
			...prev,
			duration:jumlah*30
		}))
	}

    return(

      <div className='main-css'>
			<div className='left-box-scroll' style={{ 
				backgroundColor:"#ebebeb" ,
				boxShadow:"1px 1px 10px 0px black",
				width:isTablet||isMobile?"100%":"50%",
				height:isTablet||isMobile?"100vh":"95vh",
				borderRadius:10,
				display:"flex",
				flexDirection:"column",
				justifyContent:"center"}}>
				{(!isMobile&&!isTablet)&&
				<div className='text-content' style={{ }}>
					<div  style={{ fontSize:isMobile || isTablet ?28 : '2vw',color:'#344054'}} className="text-judul" >Register</div>
				</div>
				}
				{/* <img src={UruzB} className='logo-image'></img> */}
				{/* <div style={{ width:'100%',display:"flex",justifyContent:"center",alignItems:"center"}}>
					<div style={{ height:"auto",display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: isMobile || isTablet ? 'column' : 'row', flexWrap: 'wrap',width: isMobile||isTablet?"50":"87%"}}>
						{
							dataChoose && dataChoose.map((items, index) => {
								return (
									<Cards
										title={items?.id}
										deskripsi={items.name}
										duration={items.duration}
										img={pc}
										isMobile={isMobile}
										isTablet={isTablet}
										// button1='Pilih'
										items={items}
										index={index}
										tampungindex={tampungindex}
										handleCardClick={handleCardClick}
									/>
								)
							})
						}

					</div>
				</div> */}
				<div className='padding-content'>
					<div style={{ width:isMobile||isTablet?"100%":"45%"}}>
						<Gap height={15} />
						<div className='content-style'>
							<div style={{width:isMobile||isTablet?'60%':'75%'}}>
								<Input onChange={(val)=>{
									console.log(val)
									setData((prev) => ({
										...prev,
										email:val.target.value
									}))
								}} type="email" value={data.email} placeholder="sample@gmail.com" label="E-mail" required/>
							</div>
							<Gap width={'5%'} />
							<div  onClick={()=>{handleClick();verifikasi()}} style={{width:isMobile||isTablet?'30%':'30%'}}>
								<div ref={buttonRef} onClick={()=>{}}
								className='button-styles-regis'
								>
									<div style={{display:'flex'}}>
									<text className='text-judul' style={{color:'white',fontSize:"1.5vh"}}>VERIFIKASI</text>
									</div>
								</div>
							</div>
						</div>
						<Gap height={5} />
						<div style={{width:'100%'}}>
						<Input type="password" value={data.code} onChange={(val)=>{
							setData((prev) => ({
								...prev,
								code:val.target.value
							}))
						}} placeholder="*******" label="Kode Verifikasi" />
						</div>
						<Gap height={5} />
						{/* telp */}
						<div className='content-style'>
							<div style={{width:isMobile||isTablet?'25%':'70px',display:'flex',justifyContent:'center',alignItems:'center'}}>
								<div className='phone-styles-regis'>
									<div>
										<text class="text-judul" style={{color:'white'}}>+62</text>
									</div>
								</div>
							</div>
							<div style={{width:isMobile||isTablet?'75%':'100%'}}>
								<Input onChange={(val)=>{
									console.log(val)
									setData((prev) => ({
										...prev,
										telp:val.target.value
									}))
								}} type="number" value={data.telp} placeholder="086748298329" label="Telp"/>
							</div>
						</div>
						<Gap height={5} />
							<Input type="text" value={data.name} onChange={(val)=>{
							setData((prev) => ({
								...prev,
								name:val.target.value
							}))
						}} placeholder="Adi" label="Name" />
					</div>
					<Gap width={20} />
					<div style={{ width:isMobile||isTablet?"100%":"45%" }}>
						<Gap height={15} />
						<Input type="text" value={data.ref} onChange={(val)=>{
						setData((prev) => ({
							...prev,
							ref:val.target.value
						}))
					}} placeholder="*******" label="Kode Referral (Optional)" />
						<Gap height={5} />
						<div style={{ display:"flex",flexDirection:isMobile||isTablet?"row":"column" }}>
						<Input type="password" value={data.pass} onChange={(val)=>{
							setData((prev) => ({
								...prev,
								pass:val.target.value
							}))
						}} placeholder="*******" label="Password" />
						<Gap height={5} />
						{
							(isMobile||isTablet)&&
							<Gap width={10}/>
						}
						<Input type="password" value={data.pass2} onChange={(val)=>{
							setData((prev) => ({
								...prev,
								pass2:val.target.value
							}))
						}} placeholder="*******" label="Repeat Password" />
						</div>
						<Gap height={5} />
						<div style={{ display:"flex" }}>
						<Input type="number" value={data.risk_management} onChange={(val)=>{
							setData((prev) => ({
								...prev,
								risk_management:val.target.value
							}))
						}} placeholder="10%" label="Risk Management" />
						<Gap width={"1vw"}/>
												{
							(isMobile||isTablet)&&
							<Gap width={5}/>
						}
						<Input type="number" value={data.profit_expectation} onChange={(val)=>{
							setData((prev) => ({
								...prev,
								profit_expectation:val.target.value
							}))
						}} placeholder="10%" label="Profit Expectation" />
						</div>
					</div>
				</div>
								<Gap height={10} />
				<div style={{ width:"80%",marginLeft:"10%",display:"flex",justifyContent:"space-between" }}>
					{
						dataChoose&&dataChoose.map((items,index)=>{
							return(
								<div onClick={()=>{handleCardClick(items,index)}} 
								style={{
									cursor:"pointer",
									padding:"2%",
									display:"flex",
									boxShadow:"1px 1px 10px 0px gray",
									flexDirection:"column",
									 justifyContent:"center",
									 alignItems:"center",
									 backgroundColor:tampungindex==index?"#344054":"white",
									 width:"30%",
									 height:isMobile||isTablet?"11vh":"17vh",borderRadius:5}}>
									<text className='text-judul' style={{ color:tampungindex==index?"white":"#344054" }}>{items.id}</text>
									<Gap height={"1.5vh"}/>
									<div style={{ width:"100%",display:"flex",alignItems:"center" }}>
										<ChevronDoubleRightIcon style={{ color:"white",width:isTablet||isMobile?"12px":"18px",height:isTablet||isMobile?"12px":"18px"}}/>
										<text className='text-des' style={{ fontSize:isMobile||isTablet?"1.2vh":"1.5vh",textAlign:"start",marginLeft:"1vh",color:tampungindex==index?"white":"#344054" }}>
										{isMobile||isTablet?"Fee :"+items.commission+"%":"Management Fee "+items.commission+"% Dari Keuntungan"}</text>
									</div>
									<Gap height={"1vh"}/>
									<div style={{ width:"100%",display:"flex",alignItems:"center" }}>
										<ChevronDoubleRightIcon style={{ color:"white",width:isTablet||isMobile?"16px":"13px",height:isTablet||isMobile?"16px":"13px" }}/>
										<text className='text-des' style={{ fontSize:isMobile||isTablet?"1.2vh":"1.5vh",textAlign:"start",marginLeft:"1vh",color:tampungindex==index?"white":"#344054" }}>
										{items.description}</text>
									</div>
				
								</div>
							)
						})
					}
				</div>
								<Gap height={5} />
				<div className='ceklis-box'>
					<div  style={{width:isMobile||isTablet?'80%':'80%'}} class="form-check">
					<input onClick={()=>{setCeklis(!ceklis)}} class="form-check-input" type="checkbox" id="check1" name="option1" value="something" />
					<label className='text-des' style={{paddingLeft:'2%',color:"#344054",fontSize:isMobile||isTablet?"1.2vh":"1.7vh"}} class="form-check-label">Saya menyatakan setuju untuk membayar biaya pendaftaran sebagai syarat pendaftaran pada website ini</label>
					</div>
				</div>
				<Gap height={5} />
				<div className='padding-content'>
					{
						<button onClick={()=>{
							if(ceklis==true&&verif==true){
								handleClick3();daftarAkun()
							}
							else{
								toast.info("harap centang kolom diatas")
							}
						}}
						style={{opacity:(ceklis==true&&verif==true)?1:0.4}} className='button-styles-regis' ref={buttonRef3}
						>
							<div style={{display:'flex'}}>
							{/* <img src={Save} style={{width:'2.5vh',height:'2.5vh'}}></img> */}
							{/* <Gap width={'2vh'}></Gap> */}
							<text className='text-judul' style={{color:'white'}}>DAFTAR</text>
							</div>
						</button>
					}
				</div>
				<div className="text-des box-button-text" style={{ marginTop:"1%" }}>
					<text style={{ fontSize:isMobile || isTablet ?16: '0.9vw',color:'#344054'}} className="text-des">have an account? </text>
					<text onClick={()=>{
						// onForget()
						navigate("/login");
					}} style={{ fontSize:isMobile || isTablet ?16: '0.9vw',color:"rgb(39, 94, 148)",marginLeft:"0.5%",cursor:"pointer"}} className="text-des">Login Here </text>
				</div>
			</div>	
			<ToastContainer />
			<div id="sign-in-button"></div>
      </div>
    )
}

export default Home