// Navbar.jsx

import React from "react";
import { Link } from "react-router-dom";
import './footer.css'
import Logo from '../../assets/UruzWhite.png'
import Button from "../button/button";
import { useNavigate } from "react-router-dom";
import Gap from "../Gap/Gap";
import insta from "../../assets/insta.png"
import youtube from "../../assets/Youtube.png"
import Email from "../../assets/Email.png"
import Telp from "../../assets/Telp.png"

export default function Footer({isMobile=false,isTablet=false}) {

  const navigate = useNavigate();
  console.log("isinya",isMobile)

  return (
    <div>
        <div className="main" style={{width:"100vw", flexDirection:'row',alignItems:'center',height:'50vh',justifyContent:'center'}}>
            {/* <div className="content-footer" style={{width:isMobile||isTablet?'50%':'30%',alignItems:isMobile||isTablet?"center":'flex-start'}}>
                <text className="judul" style={{fontSize:isMobile||isTablet?'3vh':'3vh'}}>BAI</text>
                <text onClick={()=>{navigate('/')}} className="deskripsi" style={{fontSize:'2.5vh'}}>Tentang</text>
                <a href="https://uruz.id" target="_blank" rel="noopener noreferrer" className="deskripsi" style={{fontSize:'2.5vh',textDecoration:'none'}}>Produk Kami</a>
            </div> */}
            <div className="content-footer" style={{width:isMobile||isTablet?'45%':"10%",alignItems:'flex-start'}}>
                <text className="judul" style={{fontSize:'3vh'}}>Bantuan</text>
               <a href="whatsapp://send?phone=+62817392286" className="deskripsi" style={{fontSize:'2.5vh',textDecoration:'none'}}>Hubungi Kami</a>
                <a href="whatsapp://send?phone=+62817392286" className="deskripsi" style={{fontSize:'2.5vh',textDecoration:'none'}}>Support Center</a>
                <text className="deskripsi" style={{fontSize:'2.5vh',height:"10vh"}}></text>
            </div>
  
            <div className="content-footer" style={{width:'50%',alignItems:'flex-start'}}>
                <text className="judul" style={{fontSize:'3vh'}}>Media Sosial</text>
                <a href="https://www.instagram.com/uruzsolusiteknologi/?igsh=b24zaXV6MHZjZ2t1" target="_blank" rel="noopener noreferrer" style={{display:'flex',justifyContent:'center',alignItems:'center',paddingTop:'2%',textDecoration:'none'}}>
                    <img src={insta} style={{width:'6vh',height:'6vh'}}></img>
                    <text className="deskripsi" style={{fontSize:'2.5vh',marginLeft:'1vh'}}>Instagram</text>
                </a>
                <a href="https://www.youtube.com/@UruzSolusiTeknologi" target="_blank" rel="noopener noreferrer" style={{display:'flex',justifyContent:'center',alignItems:'center',paddingTop:'2%',textDecoration:'none'}}>
                    <img src={youtube} style={{width:'6vh',height:'6vh'}}></img>
                    <text className="deskripsi" style={{fontSize:'2.5vh',marginLeft:'1vh'}}>Youtube</text>
                </a>
             
                <a href="mailto:bantengassetinvestama@gmail.com" style={{display:'flex',justifyContent:'center',alignItems:'center',paddingTop:'2%',textDecoration:'none'}}>
                    <img src={Email} style={{width:'6vh',height:'6vh'}}></img>
                    <text className="deskripsi" style={{fontSize:'2.5vh',marginLeft:'1vh'}}>{isMobile||isTablet?"E-mail":"bantengassetinvestama@gmail.com"}</text>
                </a>
                

            </div>
            
        </div>

        <div style={{backgroundColor:'#282c34',width:'100%',height:'8vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <text className="deskripsi" style={{fontSize:"2.5vh"}}>© 2023 PT. Uruz Solusi Teknologi. All rights reserved.</text>
        </div>
    </div>
  );
};

