
import '../Dashboard/userprofile.css';
import '../deposit/deposit.css'
import Gap from '../../component/Gap/Gap';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import pc from '../../assets/pc.png'
import DatePicker from "react-datepicker";
import Input from '../../component/Input/input';
import qrbg from "../../assets/qris204.png"
import qr from "../../assets/qr.jpg"
import FETCH_API from '../../config';
import { baseURL } from '../../Constant';
import Navbar from '../../component/navbar/navbar';
import { useNavigate } from "react-router-dom";
import LoadingSpinner from '../../component/loading/loading';
import SideBar from '../../component/sideBar/sideBar';
import trade1 from '../../assets/screen.png'

export default function Deposit({}){
    const Id = localStorage.getItem('ID')
    const [loading,setLoading]=React.useState(false)
    const [amount,setAmount]=React.useState(0)
    const navigate = useNavigate();
        const [qrParam,setQRParam]=React.useState(false)
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 768 && window.innerWidth <= 1024);

    async function deposit(){
            try {
                setLoading(true)
            const laporan = await FETCH_API("wallet/deposit", {
                "userId": Id,
                "amount":amount,
                "successRedirectUrl": baseURL+"#/wallet"
            },navigate)
            console.log(laporan)
            if(laporan.status === 'OK'){
                toast.success(laporan.message)
                window.location.href = laporan.data.invoice_url
                setLoading(false)
                // navigate("/")
            }
            else{
                if(laporan.message!==undefined){
                    toast.error(laporan.message)
                }
                else{
                    toast.error(laporan.error.message)
                }
                 setLoading(false)
            }
        } catch (error) {
            toast.error(error)
             setLoading(false)
        }

    }

    return(
        <div>
        {loading==true&&
                <LoadingSpinner/>
            }
                        <SideBar isMobile={isMobile} isTablet={isTablet}/>
            <Navbar/>
            <Gap height={70}></Gap>
                        {/* <div style={{  marginLeft:"18%",marginBottom:"1%"}}>
                <text className='text-des' style={{ fontSize:"1.2vw",color:"black" }}>USER DEPOSIT</text>
            </div> */}  
            <div style={{marginLeft:isMobile||isTablet?"23vw":"17.5vw",borderRadius:5,boxShadow:"0 0 4px gray",width:"45vw"}}>
                <div style={{ width:"100%",height:"6vh",backgroundColor:"#126bc4",borderTopLeftRadius:5,borderTopRightRadius:5,alignItems:"center",display:"flex",paddingLeft:"2%"}}>
                    <text className='text-des' style={{ fontSize:"1.2vw",color:"white" }}>DEPOSIT</text>
                </div>
                <div className='main-content-deposit-3' style={{width:'98%'}}>
                    <Gap height={'2vh'}/>
                    {qrParam&&
                    <>
                    <text className="text-judul" style={{color:'#2A2626',fontSize:'3vh'}}>DEPOSIT</text>
                    <Gap height={'2vh'}/>
                    </>
                    }
                    <div style={{width:'100%'}}>
                    <Input color={"#2A2626"} type="text" onChange={(val)=>{
                    }} placeholder="*******" label="User ID" value={Id} disabled/>
                    </div>
                    <Gap height={15} />
                    <div style={{width:'100%'}}>
                    <Input color={"#2A2626"} type="number" onChange={(val)=>{
                        setAmount(val.target.value)
                    }} placeholder="*******" label="Nominal"  value={amount}/>
                    </div>
                    <Gap height={15} />
                </div>
                <div style={{ width:"100%",height:"9vh",backgroundColor:"#ebebeb",borderTopLeftRadius:5,borderTopRightRadius:5,alignItems:"center",display:"flex",paddingLeft:"2%" }}>
                        <div style={{width:'20%'}}>
                            <div onClick={()=>{deposit();}}
                            style={{backgroundColor:'#6AC673'}} className='button-deposit'
                            >
                                <div style={{display:'flex'}}>
                                <text className='text-judul' style={{color:'white'}}>PROSES</text>
                                </div>
                            </div>
                        </div>
                        <Gap width={'5%'} />
                        <div style={{width:'20%'}}>
                            <div onClick={()=>{
                                navigate(`/wallet`)
                                }}
                            style={{backgroundColor:'#F74949'}} className='button-deposit'
                            >
                                <div style={{display:'flex'}}>
                                <text className='text-judul' style={{color:'white'}}>CANCEL</text>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}