    import * as XLSX from "xlsx";

    export function importPreparation(validations, evt, startRow = 0) {
        return new Promise((resolve, reject) => {
            console.log("startRow", startRow);
            
            const target = evt.target;
            if (target.files.length !== 1) {
            const result = {
                status: "ERR",
                messages: "Please select only one file.",
            };
            reject(result);
            }

            const reader = new FileReader();
            reader.onload = (e) => {
            const bstr = e.target.result;
            const workbook = XLSX.read(bstr, { type: "binary" });
            let dataImports = {};

            for (let x = 0; x < validations.length; x++) {
                const sheetName = workbook.SheetNames[x];
                
                if (!sheetName) {
                reject(`Sheet ${x + 1} for ${validations[x].sheetName} not found.`);
                }
                if (sheetName !== validations[x].sheetName) {
                reject(`Sheet ${x + 1} must be named ${validations[x].sheetName}.`);
                }

                console.log('sheetName', sheetName);
                const worksheetData = workbook.Sheets[sheetName];
                console.log('worksheet', worksheetData);
                const excelData = XLSX.utils.sheet_to_json(worksheetData, { header: startRow + 1 });
                const dataToImport = excelData.filter((item) => item.length > 0);
                console.log('dataToImport', dataToImport);

                const columns = {};
                for (let i = 0; i < 100; i++) {
                const item = dataToImport[startRow][i];
                if (item !== undefined) {
                    columns[item] = i;
                }
                }
                console.log("columns", columns);

                for (const property in validations[x].columns) {
                if (columns[property] === undefined && validations[x].columns[property]['required']) {
                    reject(`Column ${property} not found.`);
                }
                }

                if (dataToImport.length > 10001) {
                reject("Max 10,000 items in one upload.");
                } else {
                dataImports[sheetName] = {
                    name: sheetName,
                    data: dataToImport,
                    mapping: columns,
                };
                }
            }
            resolve(dataImports);
            };

            reader.readAsBinaryString(target.files[0]);
        });
    }