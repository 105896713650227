
import '../Dashboard/userprofile.css';
import Gap from '../../component/Gap/Gap';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import pc from '../../assets/pc.png'
import Cards2 from '../../component/card/cardStrategy';
import DatePicker from "react-datepicker";
import Modal from '../../component/modal/modal';
import '../AdminSetting/adminSetting.css'
import Empty from '../../component/empty/empty';
import logoCalendar from "../../assets/CalendarBlues.png"
import Refresh from "../../assets/RefreshWhite.png"
import Navbar from '../../component/navbar/navbar';
import { useNavigate } from "react-router-dom";
import trade1 from '../../assets/trade.png'
import trade2 from '../../assets/trade2.png'
import trade3 from '../../assets/trade3.png'
import profilewhite from '../../assets/userGray.png'
import FETCH_API from '../../config';
import { Card, Form, FloatingLabel, Col } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import formatterMoney from '../../component/formatMoney';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { CalendarIcon} from '@heroicons/react/24/outline';
import * as XLSX from "xlsx";
import { prepareImport } from '../../component/excel/prepareImport';
import LoadingSpinner from '../../component/loading/loading';
import SideBar from '../../component/sideBar/sideBar';

export default function AdminSetting({
}){
    const [loading,setLoading]=React.useState(false)
    const fileInputRef = React.createRef();
    const navigate = useNavigate();
    const buttonRefReport = React.useRef(null);
    const [items,setItems]=React.useState()
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 768 && window.innerWidth <= 1024);
    const [dateWallet,setDateWallet]=React.useState(formatDateFirst(new Date()))
    const [date2Wallet,setDate2Wallet]=React.useState(lastYear(new Date()))
    const Id = localStorage.getItem('ID')
    const [user,setUser]=React.useState([])
    function formatDateFirst(params) {
        return moment(params).format('YYYY-MM-01')
    }
        function formatDateNew(params) {
        return moment(params).format('YYYY-MM-DD')
    }
            function lastYear(params) {
        return moment(params).format('2025-MM-DD')
    }

    React.useEffect(()=>{
        getHistory()
    },[])
    
    const handleClick = () => {
		if (buttonRefReport.current) {
            // buttonRef.current.classList.remove('animate-click');
            void buttonRefReport.current.offsetWidth; // Trigger reflow to reset animation
            // buttonRef.current.classList.add('animate-click');
            buttonRefReport.current.classList.add('color-change-report');
            setTimeout(() => {
                buttonRefReport.current.classList.remove('color-change-report');
            }, 300); // Adjust this timeout to match your animation duration
        }
    };

    function formatDate(params) {
        return moment(params).format('MMMM DD, YYYY')
    }

    async function getHistory(params) {
        try {
            setLoading(true)
            let body={
                "userId": Id,
                "page": 1,
                "pageSize": 9999,
                "startDate": formatDateNew(dateWallet),
                "endDate": formatDateNew(date2Wallet)
            }
            const laporan = await FETCH_API("trading/get-trading-history", body,navigate)
            console.log(laporan)
            if(laporan.status === 'OK'){
                // toast.success(laporan.message)
                setUser(laporan.tradingHistory)
                // navigate("/")
            }
            else{
                if(laporan.message!==undefined){
                    toast.error(laporan.message)
                }
                else{
                    toast.error(laporan.error.message)
                }
            }
            setLoading(false)
        } catch (error) {
            toast.error(error)
            setLoading(false)
        }
    }


    return(
        <div style={{ width:"100vw" }}>
        {loading==true&&
                <LoadingSpinner/>
            }
                        <SideBar isMobile={isMobile} isTablet={isTablet}/>
            <Navbar/>
            <Gap height={70}></Gap>
            {/* <div style={{  marginLeft:"15%",marginBottom:"1%"}}>
                <text className='text-des' style={{ fontSize:"1.2vw",color:"black" }}>COPY TRADING</text>
            </div> */}
            <div className='main-profile' style={{ marginLeft:isMobile||isTablet?"23%":"17.5%",width:isMobile||isTablet?"73.5vw":"80vw",backgroundColor:"white",borderRadius:5,marginBottom:"10%",boxShadow:"0 0 4px gray"}}>
                <div style={{ width:"100%",height:isMobile||isTablet?"9vh":"6vh",backgroundColor:"#126bc4",borderTopLeftRadius:5,borderTopRightRadius:5,alignItems:"center",display:"flex",paddingLeft:"2%"}}>
                        <text className='text-des' style={{ fontSize:isMobile||isTablet?"1.8vw":"1.2vw",color:"white" }}>Transaction History</text>
                </div>
                <Gap height={"2vh"}/>
                <div style={{width:'100%',height:isMobile||isTablet?'10vh':'8vh',display:'flex',justifyContent:'flex-start',alignItems:'center',paddingLeft:isMobile||isTablet?'2%':'2%'}}>
                        <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
                            {/* <text className='text-judul' style={{color:'gray',paddingRight:'5%'}}>From</text> */}
                            <DatePicker 
                                onChange={(v)=>{
                                    setDateWallet(formatDate(v))
                                    }}
                                customInput={
                                <div
                                style={{backgroundColor:'rgb(238, 238, 238)',boxShadow:'0 0 1px black',borderRadius:5,width:isMobile||isTablet?'180px':'180px',height:'48px',justifyContent:'center',alignItems:'center',display:'flex',cursor:'pointer'}}
                                >
                                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <CalendarIcon  style={{width:'2vh',height:'2vh'}}></CalendarIcon>
                                    <Gap width={'1vh'}></Gap>
                                    <text className='text-des' style={{color:'rgb(48, 48, 48)',fontSize:isMobile||isTablet?'1.2vw':"0.9vw"}}>{formatDate(dateWallet)}</text>
                                    </div>
                                </div>}
                            />
                        </div>
                        <Gap width={isMobile||isTablet?'10px':'2px'}></Gap>
                        <div style={{width:'5vh',height:'5vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <text className='text-des' style={{color:'rgb(48, 48, 48)',paddingRight:'5%',fontSize:isMobile||isTablet?'1.2vw':"0.9vw"}}>To</text>
                        </div>
                        <Gap width={isMobile||isTablet?'10px':'2px'}></Gap>
                        <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
                            {/* <text className='text-judul' style={{color:'gray',paddingRight:'5%'}}>To</text> */}
                            <DatePicker 
                                onChange={(v)=>{
                                    setDate2Wallet(formatDate(v))
                                    }}
                            customInput={
                                <div
                                style={{backgroundColor:'rgb(238, 238, 238)',boxShadow:'0 0 1px black',borderRadius:5,width:isMobile||isTablet?'180px':'180px',height:'48px',justifyContent:'center',alignItems:'center',display:'flex',cursor:'pointer'}}
                                >
                                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <CalendarIcon  style={{width:'2vh',height:'2vh'}}></CalendarIcon>
                                    <Gap width={'1vh'}></Gap>
                                    <text className='text-des' style={{color:'rgb(48, 48, 48)',fontSize:isMobile||isTablet?'1.2vw':"0.9vw"}}>{formatDate(date2Wallet)}</text>
                                    </div>
                                </div>}
                            />
                        </div>
                        <Gap width={isMobile||isTablet?'10px':'10px'}></Gap>
                        <div onClick={()=>{
                            getHistory()
                            // handleClick()
                            // reportWallet('ref')
                            }} style={{width:isMobile||isTablet?'30%':'5%',marginTop:isMobile||isTablet?'5%':0,marginBottom:isMobile||isTablet?'5%':0}}  className='robot-center'>
                                <div ref={buttonRefReport}
                                style={{ 
                                    backgroundColor: '#343a3d',
                                    borderRadius: '10px',
                                    width: '45px',
                                    height: '46px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                                }}
                                >
                                    <div style={{display:'flex'}}>
                                        <img src={Refresh} style={{ width:'20px',height:'20px' }}></img>
                                        {/* <Gap width={"10px"}/>
                                    <text className='text-judul' style={{color:'white'}}>Refresh</text> */}
                                    </div>
                                </div>
                        </div>
                        
                </div>
                {/* {
                user?.length!==0&&
                    <div style={{width:isMobile||isTablet?'100%':'96%',margin:isMobile||isTablet?0:'5%',boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',borderTopLeftRadius:10,borderTopRightRadius:10 }}>
                        <div style={{ width:'100%',height:'48px',backgroundColor:'rgb(48, 48, 48)',borderTopLeftRadius:10,borderTopRightRadius:10,display:'flex'}}>
                            <div style={{ width:'20%',height:'48px',display:'flex',justifyContent:'center',alignItems:'center'  }}>
                                <text className='text-judul' style={{ fontSize:'14px' }}>Date</text>
                            </div>
                            <div style={{ width:'20%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                <text className='text-judul' style={{ fontSize:'14px' }}>Currency</text>
                            </div>
                            <div style={{ width:'20%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                <text className='text-judul' style={{ fontSize:'14px' }}>Amount</text>
                            </div>
                            <div style={{ width:'20%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                <text className='text-judul' style={{ fontSize:'14px' }}>Type</text>
                            </div>
                            <div style={{ width:'20%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                <text className='text-judul' style={{ fontSize:'14px' }}>Status</text>
                            </div>
                        </div>
                        {
                                user?.map((items,index)=>{
                                    let backgroundColor = index % 2 === 0 ?  'rgb(248, 248, 248)':'rgb(228, 228, 228)';
                                    return(
                                        <div style={{ width:'100%',height:'48px',backgroundColor,display:'flex'}}>
                                            <div style={{ width:'20%',height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                                <text className='text-des' style={{ fontSize:isTablet||isMobile?'11px':'14px',color:'rgb(48, 48, 48)' }}>{formatDate(items.transaction_time)}</text>
                                            </div>
                                            <div style={{ width:'20%',height:'48px',display:'flex',justifyContent:'center',alignItems:'center'  }}>
                                                <text className='text-des' style={{ fontSize:isTablet||isMobile?'11px':'14px',color:'rgb(48, 48, 48)' }}>{items?.currency}</text>
                                            </div>
                                            <div style={{ width:'20%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                                <text className='text-des' style={{fontSize:(isTablet||isMobile)?'11px':'14px',color:'rgb(48, 48, 48)',textAlign:"end"}}>{items.amount}</text>
                                            </div>
                                            <div style={{ width:'20%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                                <text className='text-des' style={{color:'rgb(48, 48, 48)',fontSize:(isTablet||isMobile)?'11px':'14px'}} >{items.transaction_type}</text>
                                            </div>
                                            <div style={{ width:'20%' ,height:'48px',display:'flex',justifyContent:'center',alignItems:'center' }}>
                                                <text className='text-des' style={{color:items.status=="Completed"?"#6AC673":'rgb(48, 48, 48)',fontSize:(isTablet||isMobile)?'11px':'14px'}} >{items.status}</text>
                                            </div>

                                        </div>
                                    )
                                })
                            }
                    </div>
                } */}
                    {/* <div className="table-container" style={{ padding:"1%" }}>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ color:"#2A2626" }}>Date</th>
                                    <th style={{ color:"#2A2626" }}>Currency</th>
                                    <th style={{ color:"#2A2626" }}>Amount</th>
                                    <th style={{ color:"#2A2626" }}>Type</th>
                                    <th style={{ color:"#2A2626" }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                user?.map((items,index)=>{
                                let backgroundColor = index % 2 === 0 ?  'rgb(248, 248, 248)':'rgb(228, 228, 228)';
                                return(
                                <tr>
                                    <td>{formatDate(items.transaction_time)}</td>
                                    <td>{items.currency}</td>
                                    <td style={{ textAlign:"end" }}>{formatterMoney(items.amount)}</td>
                                    <td>{items.transaction_type}</td>
                                    <td style={{color:items.status=="Completed"?"#6AC673":'rgb(48, 48, 48)'}}>{items.status}</td>
                                </tr>
                                )
                                })
                            }
                            </tbody>
                        </table>
                    </div> */}
                                             <div style={{ padding:"2%",width:"100%",display:"flex",flexDirection:"column" }}>
                            <div style={{ boxShadow:"0 0 1.5px 0 gray",borderRadius:5 }}>
                                <div style={{ display:"flex",width:"100%",backgroundColor:"#ebebeb",borderTopLeftRadius:5,borderTopRightRadius:5}}>
                                    <div className='text-judul' style={{ width:"30%",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw"}}>Date</div>
                                    <div className='text-judul' style={{ width:"15%",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw" }}>Currency</div>
                                    <div className='text-judul' style={{ width:"10%",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw" }}>Amount</div>
                                     <div className='text-judul' style={{ width:"25%",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw" }}>Type</div>
                                      <div className='text-judul' style={{ width:"20%",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw"}}>Status</div>
                                
                                </div>
                                                                {
                                    user?.map((items,index)=>{
                                    let backgroundColor = index % 2 === 0 ?  'rgb(248, 248, 248)':'#ebebeb';
                                    return(
                                   <div style={{ display:"flex",width:"100%",backgroundColor:backgroundColor,borderBottomRightRadius:5,borderBottomLeftRadius:5}}>
                                        <div style={{ width:"30%",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw" }}>{formatDate(items.transaction_time)}</div>
                                        <div style={{ width:"15%",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw" }}>{items.currency}</div>
                                        <div style={{ width:"10%",padding:"1%",textAlign:"end",fontSize:isMobile||isTablet?'1.2vw':"1vw" }}>{formatterMoney(items.amount)}</div>
                                        <div style={{ width:"25%",padding:"1%",fontSize:isMobile||isTablet?'1.2vw':"1vw" }}>{items.transaction_type}</div>
                                        <div style={{ width:"20%",padding:"1%",color:items.status=="Completed"?"#6AC673":"",fontSize:isMobile||isTablet?'1.2vw':"1vw" }}>{items.status}</div>
          
                                
                                    </div>
                                    )
                                    })
                                }
                            </div>
                        </div> 
                {
                    user?.length===0&&
                    <Empty data={"User"}></Empty>
                }
            </div>
            <ToastContainer />
        </div>
    )
}

const styles = {
  container: {
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    marginTop:'5%'
  },
  header: {
    // fontSize: '6vw',
    // marginBottom: '20px',
    // color: '#3498db',
  },
  totalsContainer: {
    backgroundColor: '#F5F9FD',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent:"space-between",
    alignItems: 'center',
  },
  totalItem: {
    flex: 1,
    padding: '10px',
  },
  label: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#333',
  },
  value: {
    fontSize: '1.5rem',
    color: '#2ecc71',
    marginBottom: '20px',
  },
    value2: {
    fontSize: '1.5rem',
    color: '#e74c3c',
    marginBottom: '20px',
  },
  percentageValue: {
    fontSize: '1.5rem',
    color: 'gray',
  },
};